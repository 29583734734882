import React from 'react';
import './ServiceCard.css'

import seviceCardbgOne from '../../../assets/images/pages-images/services-page-images/services-bg-one.png' 
import seviceCardbgTwo from '../../../assets/images/pages-images/services-page-images/services-bg-two.png' 
import seviceCardbgThree from '../../../assets/images/pages-images/services-page-images/services-bg-three.png' 
import seviceCardbgFour from '../../../assets/images/pages-images/services-page-images/services-bg-four.png' 
import { NavHashLink } from 'react-router-hash-link';



const serviceCardAllBg = {
    0: seviceCardbgOne,
    1: seviceCardbgTwo,
    2: seviceCardbgThree,
    3: seviceCardbgFour,
}

const ServiceCard = ({cardTitle,bgImage,cardInfos}) => {
    return (
        <div className='service-card-main-container'>
            <div className='service-card-inner-front-container service-card-face-front'>
                <div className='service-card-inner-front-container-wrapper'>
                    <div className='service-card-inner-front-image-holder'>
                        <img src={serviceCardAllBg[bgImage]} alt={cardTitle}/>
                    </div>
                    <div className='service-card-inner-front-text-wrapper'>
                        <h2>{cardTitle}</h2>
                    </div>
                </div>
            </div>
            <div className='service-card-inner-back-container service-card-face-back'>
                <div className='service-card-inner-back-container-wrapper'>
                    {
                        Object.entries(cardInfos).map((infos,index)=>{
                            return(
                            <div className='service-card-back-single-info-wrapper'>
                                <div className='service-card-single-info-inner-container'>
                                    <div className='service-card-single-info-inner-image-holder'>
                                        <img  src={require(`../../../assets/images/pages-images/services-page-images/services-icons/s_icon_${cardInfos[index]["iconNum"]}.png`)} alt=""/>
                                    </div>
                                    <div className='service-card-single-info-inner-text-holder'>
                                        <h2>{infos[1]["servicesDes"]}</h2>
                                    </div>
                                </div>
                            </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default ServiceCard;