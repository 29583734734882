import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import './SharedHostCallBookContainer.css'

const SharedHostCallBookContainer = ({text,btnAction,btnVal}) => {
    return (
        <div className='shared-host-call-book-container-main-container'>
            <div className='shared-host-call-book-container-text-wrapper'>
                <h2>{text}</h2>
            </div>
            <button className='shared-host-call-book-container-btn' onClick={()=>btnAction()}>
                {btnVal}
            </button>
        </div>
    );
};

export default SharedHostCallBookContainer;