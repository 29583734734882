import React, { useState } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import './Footer.css'

// imported images
import topNavImage from '../../../assets/images/backgrounds/top-color-mixture-1.png'
import footerBgImageOne from '../../../assets/images/pages-images/footer-images/footer-bg-one.svg'
import facebookIconImg from '../../../assets/images/pages-images/footer-images/footer-one.svg'
import instaIconImg from '../../../assets/images/pages-images/footer-images/footer-two.svg'
import twitterIconImg from '../../../assets/images/pages-images/footer-images/footer-three.svg'
import linkedInIconImg from '../../../assets/images/pages-images/footer-images/footer-four.svg'
import youtubeIconImg from '../../../assets/images/pages-images/footer-images/footer-five.svg'
import sileLogo from '../../../assets/images/logo/sile_logo_whole_th_white.svg'
import AboutButtonPrimary from '../../buttons/AboutButtonPrimary/AboutButtonPrimary';
import { addDoc, collection, getFirestore, Timestamp } from 'firebase/firestore';

const Footer = ({handlePopUp,handleMenuActionAlternate}) => {

    const storeDb = getFirestore()

    const [subEmail,setSubEmail] = useState('');

    const handleSubscriber = async () =>{
        if(!subEmail || (!subEmail.includes('@'))){
            // alert("please give a valid email.")
            handlePopUp(3)
            setSubEmail("");
            return
        }
        else{
            
            // const cityRef = doc(storeDb, 'sile', `subscribers`);
            const subRef = collection(storeDb, 'subscribers');
            addDoc(subRef,{
                email:subEmail,
                time: Timestamp.fromDate(new Date())
            })
            .then(()=>{
                console.log('data added')
                handlePopUp(1) // 1->successful pop up type
            })
            .then(()=>{
                handlePopUp(1)
            })
            .catch(err=>{ 
                console(err.message)
                handlePopUp(3) // -1 -> unsuccessful pop up type
            })
            
            
            // const myData = {
            //     email: subEmail
            // }
            // await addDoc(doc(storeDb,subRef), myData);
            setSubEmail("");
        }
    }


    return (
        <>
        <div onClick={()=>handleMenuActionAlternate()} className='footer-main-container'>
            <div className='footer-bg-image-holder'></div>
            <div className='footer-bg-image-holder-two'></div>
            <div className='footer-top-container'>
                <div className='footer-top-other-route-wrapper'>
                    {/* <h2>Company</h2> */}
                    <div className='footer-top-other-route-link-holder'>
                        <NavHashLink smooth to="/home">Home</NavHashLink>
                        <NavHashLink smooth to="/resources">Resources</NavHashLink>
                        {/* <NavHashLink smooth to="#">About us</NavHashLink> */}
                        <NavHashLink smooth to="/contact">Contact us</NavHashLink>
                        <NavHashLink smooth to="#" onClick={()=>{handlePopUp(4)}}>Experience WOW</NavHashLink>
                    </div>
                </div>
                <div className='footer-top-tag-info-wrapper'>
                    <div className='footer-top-tag-holder'>
                        <p>Experience Training, Get In Touch</p>
                        {/* <NavHashLink to="#">
                            <div className='footer-top-icon'>
                                <img src={linkedInIconImg} alt="a"/>
                            </div>
                        </NavHashLink> */}
                        <a href="https://www.linkedin.com/company/sile-ai/" target="_blank">
                            <div className='footer-top-icon'>
                                <img src={linkedInIconImg} alt="a"/>
                            </div>
                        </a>
                    </div>
                    <div className='footer-top-social-icon-container'>
                        <div className='footer-bottom-social-icon-input-container'>
                            <input type="email" placeholder="Your email address" value={subEmail} onChange={(e)=>{setSubEmail(e.target.value)}} />
                            <div style={{width:'133px',height:'37px'}}>
                                <AboutButtonPrimary type={4} text="Let's talk" btnAction={()=>{handleSubscriber()}}/>
                            </div>
                        </div>
                        {/* <NavHashLink to="#">
                            <div className='footer-top-icon'>
                                <img src={facebookIconImg} alt="a"/>
                            </div>
                        </NavHashLink> */}
                        {/* <NavHashLink to="#">
                            <div className='footer-top-icon'>
                                <img src={instaIconImg} alt="a"/>
                            </div>
                        </NavHashLink> */}
                        {/* <NavHashLink to="#">
                            <div className='footer-top-icon'>
                                <img src={twitterIconImg} alt="a"/>
                            </div>
                        </NavHashLink> */}
                        {/* <NavHashLink to="#">
                            <div className='footer-top-icon'>
                                <img src={linkedInIconImg} alt="a"/>
                            </div>
                        </NavHashLink> */}
                        {/* <NavHashLink to="#">
                            <div className='footer-top-icon'>
                                <img src={youtubeIconImg} alt="a"/>
                            </div>
                        </NavHashLink> */}
                    </div>
                </div>
            </div>
            <hr className='footer-horizontal-line'/>
            <div className='footer-bottom-container '>
                <div className='footer-bottom-left-text-wrapper'>
                    <div className='footer-bottom-left-logo-holder'>
                        <img src={sileLogo} alt={"sile-logo"}/>
                    </div>
                    <div className='footer-bottom-left-copy-text-container'>
                        {/* <p>Copyright © 2022-2023 SILE.ai LLC. All rights reserved</p> */}
                        <p>Copyright © 2022-2023 MetaXP Pvt Ltd. All rights reserved</p>
                    </div>
                </div>
                <div className='footer-bottom-right-information-wrapper'>
                    {/* <p>Privacy Policy</p> */}
                    {/* <p>Terms of Use</p> */}
                    <p>Site map </p>
                </div>
            </div>
            <div className='footer-main-container-inner-sm'>
                <div className='footer-main-container-inner-top'>
                    <div className='footer-main-container-inner-top-left'>
                        <div className='footer-main-container-inner-top-bg-image-container'>
                            <img src={sileLogo} alt="sile-logo"/>
                        </div>
                        <div className='footer-main-container-inner-top-copyright-text-container'>
                            <p>Copyright © 2022-2023 MetaXP Pvt Ltd. All rights reserved</p>
                        </div>
                    </div>
                    <div className='footer-main-container-inner-top-right'>
                        <div className='footer-top-icon'>
                            <img src={linkedInIconImg} alt="a"/>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
                <div className='footer-main-container-inner-bottom'>
                <div className='footer-bottom-right-information-wrapper-sm'>
                    <p>Privacy Policy</p>
                    <p>Terms of Use</p>
                    <p>Site map </p>
                </div>
                </div>
            </div>
            <div className='footer-bottom-last-random-color-image-holder'>
                <img src={topNavImage} alt="bottom-color"/>
            </div>
        </div>
        </>
    );
};

export default Footer;