import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import './AboutButtonPrimary.css';

const AboutButtonPrimary = ({text,btnAction,type}) => {
    if(type === 5){
        return (
            <div onClick={()=>btnAction()} className='about-button-primary-main-container-type-five'>
                    <button>{text}</button>
            </div>
        );
    }
    if(type === 4){
        return (
            <div onClick={()=>btnAction()} className='about-button-primary-main-container-type-four'>
                    <button>{text}</button>
            </div>
        );
    }
    if(type === 3){
        return (
            <div onClick={()=>btnAction()} className='about-button-primary-main-container-type-three'>
                    <button>{text}</button>
            </div>
        );
    }
    if(type === 2){
        return (
            <div onClick={()=>btnAction()} className='about-button-primary-main-container-type-two'>
                    <button>{text}</button>
            </div>
        );
    }
    return (
        // <div className='about-button-primary-main-container'>
        //     <NavHashLink to={`${btnLink}`} >
        //         <button>{text}</button>
        //     </NavHashLink>
        // </div>
        <div onClick={()=>btnAction()} className='about-button-primary-main-container'>
                <button>{text}</button>
        </div>
    );
};

export default AboutButtonPrimary;