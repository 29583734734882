import React, { useEffect, useState } from 'react';
import HomePageSectionFive from '../../components/HomePageSectionFive/HomePageSectionFive';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import ResourcesCardContainer from '../../components/resourcesComponents/ResourcesCardContainer/ResourcesCardContainer';
import ResourcesLandingCard from '../../components/resourcesComponents/ResourcesLandingCard/ResourcesLandingCard';
import Footer from '../../components/Shared/Footer/Footer';
import SectionTitleOne from '../../components/Shared/SectionTitleOne/SectionTitleOne';
import './ResourcesPage.css';


import resourceImageDefaultOne from '../../assets/images/pages-images/resources-page-images/resources-image-default.png'
import resourceImageOne from '../../assets/images/pages-images/resources-page-images/resources-image-one.png'
import resourceImageTwo from '../../assets/images/pages-images/resources-page-images/resources-image-two.png'
import resourceImageThree from '../../assets/images/pages-images/resources-page-images/resources-image-three.png'
import ResourcesNav from '../../components/resourcesComponents/ResourcesNav/ResourcesNav';
import { GetRandomNumber } from '../../hooks/GetRandomNumber';
import PopUpOne from '../../components/popUps/PopUpOne/PopUpOne';

import cSoonBgOne from '../../assets/images/pages-images/resources-page-images/resource-waiting-bg-image-one.svg'
import cSoonTextOne from '../../assets/images/pages-images/resources-page-images/resource-coming-soon-text-image-one.png'

const cardsInfo = [
    {
        id:0,
        imageLink: resourceImageOne,
        resTitle:"Increase engagement and learning transfer with principles from game design zero.",
        resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
        resType:"ATD Article",
        resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
        resDuration:"2 mins"
    },
    {
        id:1,
        imageLink: resourceImageDefaultOne,
        resTitle:"Increase engagement and learning transfer with principles from game design one.",
        resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
        resType:"HBR Article",
        resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
        resDuration:"2 mins"
    },
    {
        id:2,
        imageLink: resourceImageTwo,
        resTitle:"Increase engagement and learning transfer with principles from game design two.",
        resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
        resType:"ATD Article",
        resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
        resDuration:"2 mins"
    },
    {
        id:3,
        imageLink: resourceImageThree,
        resTitle:"Increase engagement and learning transfer with principles from game design three.",
        resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
        resType:"HBR Article",
        resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
        resDuration:"2 mins"
    },
    {
        id:4,
        imageLink: resourceImageDefaultOne,
        resTitle:"Increase engagement and learning transfer with principles from game design four.",
        resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
        resType:"ATD Article",
        resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
        resDuration:"2 mins"
    },
    {
        id:5,
        imageLink: resourceImageTwo,
        resTitle:"Increase engagement and learning transfer with principles from game design five.",
        resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
        resType:"ATD Article",
        resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
        resDuration:"2 mins"
    },
    {
        id:6,
        imageLink: resourceImageThree,
        resTitle:"Increase engagement and learning transfer with principles from game design six.",
        resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer. asdfdsf",
        resType:"ATD Article",
        resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
        resDuration:"2 mins"
    },
]

const timeouts = []

const ResourcesPage = ({status}) => {
    const [isOpen,setIsOpen] = useState(false);
    const [isLarge,setIsLarge] = useState(false);
    const [singleResIndex,setSingleResIndex] = useState(2)

    const [resTypes,setResTypes] = useState([])
    const [allResource,setAllResource] = useState([])
    const [activeResType,setActiveResType] = useState('All Resources')

    const[isPopUpOpen,setIsPopUpOpen] = useState(false);
    const[popUpType,setPopUpType] = useState(0);



    const handlePopUp = (type) =>{
        setPopUpType(type)
        setIsPopUpOpen(true)
        if(type !== 4){
            const myTimeOut = setTimeout(()=>{
                console.log('timedOut')
                setIsPopUpOpen(false)
                setPopUpType(0)
            },4000)
            timeouts.push(myTimeOut)
            return () => clearTimeout(myTimeOut)
        }
    }

    const handleClosePopUpBtn = () =>{
        setIsPopUpOpen(false)
        setPopUpType(0)
        for(let i=0;i<timeouts.length;i++){
            clearTimeout(timeouts[i])
        }
    }
    
    const handleMenuActionAlternate = ()=>{
        const myDiv = document.getElementById('navigation-controller-main-wrapper-id')
        if(isOpen){
            myDiv.style.right='-100vw';
            setIsOpen(false);
        }
    }
    
    useEffect(()=>{
        const myDiv = document.getElementById('resources-page-main-container')
        const myDivSize = myDiv.getBoundingClientRect();
        const tempN = myDivSize.width
        if(tempN>801){
            setIsOpen(false);
            handleMenuActionAlternate()
            setIsLarge(true)
        }
        else{
            setIsLarge(false)
        }
    },[])

    window.addEventListener('resize',e=>{
        const myDiv = document.getElementById('resources-page-main-container')
        const myDivSize = myDiv.getBoundingClientRect();
        const tempN = myDivSize.width
        if( isOpen && tempN>801){
            setIsOpen(false);
            handleMenuActionAlternate()
        }
        if(tempN<=801){
            setIsLarge(false)
        }
        else{
            setIsLarge(true)
        }
    })

    useEffect(()=>{
        const randId = GetRandomNumber(cardsInfo.length-1)
        setSingleResIndex(randId)
        if(cardsInfo){
            if(activeResType==="All Resources"){
                const tempArr = [...cardsInfo]
                tempArr.pop(singleResIndex)
                console.log(tempArr)
                setAllResource(tempArr)
            }
            else{
                // const tempArr = cardsInfo.filter((index,cI) => cI.resType === activeResType)
                const tempArr = []
                console.log(activeResType)
                for(let i=0;i<cardsInfo.length;i++){
                    if(cardsInfo[i]['resType'] === activeResType){
                        tempArr.push(cardsInfo[i])
                    }
                }
                console.log(tempArr)
                setAllResource(tempArr)
            }
        }
    },[cardsInfo,activeResType])

    useEffect(()=>{
        if(cardsInfo){
            const tempArr = []
            for(let i=0;i<cardsInfo.length;i++){
                if(!tempArr.includes(cardsInfo[i]['resType'])){
                    tempArr.push(cardsInfo[i]['resType'])
                }
            }
            console.log(tempArr)
            // tempArr.push('Videos')
            // tempArr.push('Podcasts')
            // tempArr.push('Photos')
            tempArr.unshift('All Resources')
            setResTypes(tempArr)
        }
    },[cardsInfo])
    if(status === 0){
        return (
            <div id="resources-page-main-container" className='resources-page-main-container'>
                <div style={{position:'absolute'}}>
                    <NavigationBar handlePopUp={handlePopUp} isOpen={isOpen} setIsOpen={setIsOpen}/>
                </div>
                <div className='shared-main-window-page-bottom-container' onClick={()=>handleMenuActionAlternate()}>
                {isPopUpOpen && popUpType!== 0 && <PopUpOne popUpType={popUpType} handleClosePopUpBtn={handleClosePopUpBtn} handlePopUp={handlePopUp} />}
                    <div className='resources-page-main-title-container' style={{paddingBottom:'20px'}}>
                        {/* <SectionTitleOne rotY={10} rotZ={-10} wt={148} ht={66} leftD={40} text={"Coming Soon"}/> */}
                        <p>Resources</p>
                    </div>
                    <div className='resource-page-coming-soon-inner-container'>
                        <div className='resource-page-coming-soon-left-container'>
                            <div className='resource-page-coming-soon-left-image-container'>
                                <img src={cSoonBgOne} alt="coming soon"/>
                            </div>
                        </div>
                        <div className='resource-page-coming-soon-right-container'>
                            <div className='resource-page-coming-soon-right-image-container'>
                                <img src={cSoonTextOne} alt="coming soon"/>
                           </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
    return (
        <div id="resources-page-main-container" className='resources-page-main-container'>
            <div style={{position:'absolute'}}>
                <NavigationBar handlePopUp={handlePopUp} isOpen={isOpen} setIsOpen={setIsOpen}/>
            </div>
            <div className='shared-main-window-page-bottom-container'>
            {isPopUpOpen && popUpType!== 0 && <PopUpOne popUpType={popUpType} handleClosePopUpBtn={handleClosePopUpBtn}/>}
                <div>
                    <SectionTitleOne rotY={10} rotZ={-10} wt={148} ht={66} leftD={-20} text={"Our Resources"}/>
                </div>
                <div className='resources-page-all-resources-outer-container'>
                    <div>
                        <ResourcesNav setActiveResType={setActiveResType} activeResType={activeResType} resTypes={resTypes}/>
                        <ResourcesLandingCard cardInfo={cardsInfo[singleResIndex]}/>
                        <ResourcesCardContainer cardsInfo={allResource}/>
                    </div>
                </div>
                <HomePageSectionFive  handleMenuActionAlternate={handleMenuActionAlternate}/>
                <Footer handlePopUp={handlePopUp}/>
            </div>
        </div>
    );
};

export default ResourcesPage;

/* 

<parent>
    <resourcesNav></resourcesNav>
    <resourcesLanding/></resourcesLanding>
    <resourcesBlogCardContainer></resourcesBlogCardContainer>
</parent>

*/