import React from 'react';
import './HomePageSectionEmpire.css'

import empowerImageOne from '../../../assets/images/pages-images/home-page-images/empower-image-one.png';
import empowerImageTwo from '../../../assets/images/pages-images/home-page-images/empower-image-two.png';
import empowerImageThree from '../../../assets/images/pages-images/home-page-images/empower-image-three.png';
import empowerImageFour from '../../../assets/images/pages-images/home-page-images/empower-image-four.png';
import empowerImageFive from '../../../assets/images/pages-images/home-page-images/empower-image-five.png';
import EmpowerCard from '../../cards/EmpowerCard/EmpowerCard';

const empowerCardItems = [
    {imageLink : empowerImageOne, cardTitle: "Real-time Facilitation Insights"},
    {imageLink : empowerImageTwo, cardTitle: "Modular and Configurable LMS Integrations"},
    {imageLink : empowerImageThree, cardTitle: "Complete Control of the Session at Your Fingertips"},
    {imageLink : empowerImageFour, cardTitle: "Actionable Personalized Reports"},
    {imageLink : empowerImageFive, cardTitle: "Engaged Sessions, Better Outcomes"},
]
const HomePageSectionEmpire = ({handleMenuActionAlternate}) => {
    return (
        <section className='home-page-section-empire-main-container' onClick={()=>{handleMenuActionAlternate()}}>
            <div className='home-page-section-empire-title-container'>
                <h2>We empower you with</h2>
            </div>
            <div className='empower-all-card-outer-container'>
                {/* <EmpowerCard cardInfo={empowerCardItems[0]}/> */}
                {
                    empowerCardItems.map((eC,index) =>
                        <div id={`empower-card-holder-${index}`}>
                            <EmpowerCard cardTitle={eC.cardTitle} cardImage={eC.imageLink}/>
                        </div>
                    )
                }
            </div>
        </section>
    );
};

export default HomePageSectionEmpire;