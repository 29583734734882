import React, { useEffect, useState } from 'react';
import './HomePage.css'


import NavigationBar from '../../components/NavigationBar/NavigationBar';
import StoryBar from '../../components/StoryBar/StoryBar';
import HomeLanding from '../../components/HomeLanding/HomeLanding';
import HomePageSectionTwo from '../../components/HomePageSectionTwo/HomePageSectionTwo';
import HomePageSectionThree from '../../components/HomePageSectionThree/HomePageSectionThree';
import HomePageSectionFourProgram from '../../components/HomePageSectionFourProgram/HomePageSectionFourProgram';
import HomePageSectionFive from '../../components/HomePageSectionFive/HomePageSectionFive';
import HomePageSectionSix from '../../components/HomePageSectionSix/HomePageSectionSix';
import HomePageSectionSeven from '../../components/HomePageSectionSeven/HomePageSectionSeven';
import HomePageSectionEightTestimonials from '../../components/HomePageSectionEightTestimonials/HomePageSectionEightTestimonials';
import HomePageSectionNineOurClients from '../../components/HomePageSectionNineOurClients/HomePageSectionNineOurClients';
// import HomePageSectionTenFaq from '../../components/HomePageSectionTenFaq/HomePageSectionTenFaq';
import Footer from '../../components/Shared/Footer/Footer';
import HomeBigTrainingChallenge from '../../components/HomeBigTrainingChallenge/HomeBigTrainingChallenge';
import MainViewDisplay from '../../components/MainView/MainViewDisplay/MainViewDisplay';
import HomePageSectionEmpire from '../../components/homeComponents/HomePageSectionEmpire/HomePageSectionEmpire';
import PopUpOne from '../../components/popUps/PopUpOne/PopUpOne';

const timeouts = []

const HomePage = () => {
    const [isOpen,setIsOpen] = useState(false);
    const [isLarge,setIsLarge] = useState(false);

    const[isPopUpOpen,setIsPopUpOpen] = useState(false);
    const[popUpType,setPopUpType] = useState(0);



    const handlePopUp = (type) =>{
        setPopUpType(type)
        setIsPopUpOpen(true)
        if(![4,5].includes(type)){
            const myTimeOut = setTimeout(()=>{
                console.log('timedOut')
                setIsPopUpOpen(false)
                setPopUpType(0)
            },4000)
            timeouts.push(myTimeOut)
            return () => clearTimeout(myTimeOut)
        }
    }

    const handleClosePopUpBtn = () =>{
        setIsPopUpOpen(false)
        setPopUpType(0)
        for(let i=0;i<timeouts.length;i++){
            clearTimeout(timeouts[i])
        }
    }


    const handleMenuActionAlternate = ()=>{
        const myDiv = document.getElementById('navigation-controller-main-wrapper-id')
        if(isOpen){
            myDiv.style.right='-100vw';
            setIsOpen(false);
        }
    }
    
    useEffect(()=>{
        const myDiv = document.getElementById('home-main-container')
        const myDivSize = myDiv.getBoundingClientRect();
        const tempN = myDivSize.width
        if(tempN>801){
            setIsOpen(false);
            handleMenuActionAlternate()
            setIsLarge(true)
        }
        else{
            setIsLarge(false)
        }
    },[])

    window.addEventListener('resize',e=>{
        const myDiv = document.getElementById('home-main-container')
        const myDivSize = myDiv.getBoundingClientRect();
        const tempN = myDivSize.width
        if( isOpen && tempN>801){
            setIsOpen(false);
            handleMenuActionAlternate()
        }
        if(tempN<=801){
            setIsLarge(false)
        }
        else{
            setIsLarge(true)
        }
    })


    return (
        <div id="home-main-container" className='home-page-main-container'>
            <div style={{position:'absolute'}}>
                <NavigationBar handlePopUp={handlePopUp} isOpen={isOpen} setIsOpen={setIsOpen}/>
            </div>
            <div className='shared-main-window-page-bottom-container' >
            {isPopUpOpen && popUpType!== 0 && <PopUpOne popUpType={popUpType} handleClosePopUpBtn={handleClosePopUpBtn} handlePopUp={handlePopUp} />}
            {/* <StoryBar isLarge={isLarge} handleMenuActionAlternate={handleMenuActionAlternate}/> */}
            {/* <HomeLanding handleMenuActionAlternate={handleMenuActionAlternate}/> */}
            <MainViewDisplay page={1} handlePopUp={handlePopUp} handleMenuActionAlternate={handleMenuActionAlternate} type={2}/>
            <HomePageSectionFourProgram  handleMenuActionAlternate={handleMenuActionAlternate}/>
            <HomePageSectionTwo handlePopUp={handlePopUp}  handleMenuActionAlternate={handleMenuActionAlternate}/>
            <HomePageSectionEmpire handleMenuActionAlternate={handleMenuActionAlternate} />
            {/* <HomePageSectionThree  handleMenuActionAlternate={handleMenuActionAlternate}/> */}
            <HomePageSectionFive handlePopUp={handlePopUp}  handleMenuActionAlternate={handleMenuActionAlternate}/>
            {/* <HomePageSectionSix  handleMenuActionAlternate={handleMenuActionAlternate}/> */}
            {/* <HomeBigTrainingChallenge handleMenuActionAlternate={handleMenuActionAlternate}/> */}
            {/* <HomePageSectionEightTestimonials  handleMenuActionAlternate={handleMenuActionAlternate}/> */}

            {/* blogs and resources page⬇️ */}
            {/* <HomePageSectionSeven  handleMenuActionAlternate={handleMenuActionAlternate}/> */}
            {/* blogs and resources page ⬆️ */}
            
            {/* <HomePageSectionTenFaq handleMenuActionAlternate={handleMenuActionAlternate}/>
            <div style={{display:'inline-block',background:'red',height:'500px'}}>
                <h2>en</h2>
            </div> */}
            <Footer handlePopUp={handlePopUp} handleMenuActionAlternate={handleMenuActionAlternate}/>
            </div>
        </div>
    );
};

export default HomePage;
// return (
//     <div id="home-main-container" className='home-page-main-container'>
//         <NavigationBar isOpen={isOpen} setIsOpen={setIsOpen}/>
//         {/* <StoryBar isLarge={isLarge} handleMenuActionAlternate={handleMenuActionAlternate}/> */}
//         <HomeLanding handleMenuActionAlternate={handleMenuActionAlternate}/>
//         <HomePageSectionFourProgram  handleMenuActionAlternate={handleMenuActionAlternate}/>
//         <HomePageSectionTwo  handleMenuActionAlternate={handleMenuActionAlternate}/>
//         {/* <HomePageSectionThree  handleMenuActionAlternate={handleMenuActionAlternate}/> */}
//         {/* <HomePageSectionFive  handleMenuActionAlternate={handleMenuActionAlternate}/> */}
//         {/* <HomePageSectionSix  handleMenuActionAlternate={handleMenuActionAlternate}/> */}
//         <HomeBigTrainingChallenge handleMenuActionAlternate={handleMenuActionAlternate}/>
//         <HomePageSectionEightTestimonials  handleMenuActionAlternate={handleMenuActionAlternate}/>
//         <HomePageSectionSeven  handleMenuActionAlternate={handleMenuActionAlternate}/>
//         {/* <HomePageSectionTenFaq handleMenuActionAlternate={handleMenuActionAlternate}/>
//         <div style={{display:'inline-block',background:'red',height:'500px'}}>
//             <h2>en</h2>
//         </div> */}
//         <Footer/>
//     </div>
// );