import React, { useState } from 'react'
import './NavigationBar.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

// imported image 
import topNavImage from '../../assets/images/backgrounds/top-color-mixture-1.png'
import sileLogoIcon from '../../assets/images/logo/sile_logo.png'
import sileLogoIconText from '../../assets/images/logo/sile_logo_text_one.png'
import ButtonOne from '../buttons/ButtonOne/ButtonOne'
import { NavHashLink } from 'react-router-hash-link'
import PopUpOne from '../popUps/PopUpOne/PopUpOne'

const timeouts = []

const NavigationBar = ({handlePopUp,isOpen,type,setIsOpen}) => {
  const [toolOpen,setToolOpen] = useState(false)
  const [itemType,setItemType] = useState('')

    const handleExpWow = () =>{
      // handleMenuActionOff()
      handlePopUp(4)
    }

  const handleNavItems = (title) => {
    const mytoolDiv = document.getElementById('navigation-bar-secondary-item-main-wrapper')
    if(toolOpen){
      mytoolDiv.style.top = '-140%'
      if(title === 'tools'){
        setToolOpen(false)
        setItemType('')
      }
    }
    else{
      handleMenuActionOff()
      mytoolDiv.style.top = '100%'
      if(title === 'tools'){
        setToolOpen(true)
        setItemType('tools')
      }

    }
  }

  const handleMouseEnterInTool = (title) =>{
    const mytoolDiv = document.getElementById('navigation-bar-secondary-item-main-wrapper')
    handleMenuActionOff()
    mytoolDiv.style.top = '100%'
    if(title === 'tools'){
        setToolOpen(true)
        setItemType('tools')
    }
  }
  const handleMouseOutTool = (title) =>{
    const mytoolDiv = document.getElementById('navigation-bar-secondary-item-main-wrapper')
    handleMenuActionOff()
    mytoolDiv.style.top = '-140%'
    if(title === 'tools'){
        setToolOpen(true)
        setItemType('tools')
    }
  }

  const handleMenuAction = ()=>{
    const myDiv = document.getElementById('navigation-controller-main-wrapper-id')
    if(!isOpen){
      // myDiv.style.top='35px';
      // myDiv.style.top='-15px';
      myDiv.style.right='0px';
      setIsOpen(true);
    }
    else{
      myDiv.style.right='-100vw';
      // myDiv.style.top = '-400px';
      setIsOpen(false);
    }
  }

  
  const handleMenuActionOff = ()=>{
    const myDiv = document.getElementById('navigation-controller-main-wrapper-id')
      // myDiv.style.top = '-400px';
      myDiv.style.right='-100vw';
      setIsOpen(false);
  }


  if(type === 2){
    return (
      <div className='navigation-bar-main-container'>
          <div className='navigation-bar-outer-container-wrapper'>
          <div className='navigation-bar-main-image-holder'>
              <img src={topNavImage} alt="top-nav"/>
          </div>
          <div className='navigation-bar-logo-navigators-main-wrapper'>
            <div className='navigation-bar-logo-container'>
              <div className='navigation-bar-logo-container-sile-icon'>
                <img src={sileLogoIcon} alt="sile-logo"/>
              </div>
              <div className='navigation-bar-logo-container-sile-text'>
                <img src={sileLogoIconText} alt="sile-logo-text"/>
              </div>
            </div>
            <nav style={{visibility:'hidden'}}>
              <nav>
                <ul id="navigation-controller-main-wrapper-id" className='navigation-controller-main-wrapper'>
                  <li className='navigation-item-main'>
                    <NavHashLink  onClick={handleMenuActionOff} smooth to="/home">Home</NavHashLink></li>
                  <li className='navigation-item-main'>
                    <NavHashLink  onClick={handleMenuActionOff} smooth to="/">Tools</NavHashLink></li>
                  <li className='navigation-item-main'>
                    <NavHashLink  onClick={handleMenuActionOff} smooth to="/resources">Resources</NavHashLink></li>
                  <li className='navigation-item-main'>
                    <NavHashLink  onClick={handleMenuActionOff} smooth to="/services">Services</NavHashLink></li>
                  <li className='navigation-item-main'>
                    <NavHashLink  onClick={handleMenuActionOff} smooth to="/contact">Contact us</NavHashLink></li>
                  <li className='navigation-item-main'>
                    <NavHashLink style={{color:'#66C293'}}   onClick={handleMenuActionOff} smooth to="/">Experience WOW</NavHashLink></li>
                  <li id="navigator-item-main-sign-in-btn" className='navigation-item-main'>
                  <ButtonOne text="Sign in" btnAction={()=>{console.log('signed in')}}/></li>
                </ul>
              </nav>
            </nav>
            <div style={{visibility:'hidden'}} className='navigation-bar-sign-in-wrapper'>
              <div id='navigation-bar-sign-in-btn'>
                <ButtonOne text="Sign in" btnAction={()=>{console.log('signed in')}}/>
              </div>
              <div id='navigation-bar-menu-icon-btn'>
                <ButtonOne isOpen={isOpen} type={2} text="Open menu" btnAction={handleMenuAction}/>
              </div>
            </div>
          </div>
          </div>
      </div>
    )
  }
  return (
    <div className='navigation-bar-main-container'>
      <div style={{background:'#ffffff',zIndex:5,position:'relative'}} className='navigation-bar-outer-container-wrapper'>
        <div className='navigation-bar-main-image-holder'>
            <img src={topNavImage} alt="top-nav"/>
        </div>
        <div className='navigation-bar-logo-navigators-main-wrapper'>
          <NavHashLink to="/">
          <div className='navigation-bar-logo-container'>
            <div className='navigation-bar-logo-container-sile-icon'>
              <img src={sileLogoIcon} alt="sile-logo"/>
            </div>
            <div className='navigation-bar-logo-container-sile-text'>
              <img src={sileLogoIconText} alt="sile-logo-text"/>
            </div>
          </div>
            
          </NavHashLink>
          <nav>
            <nav>
              <ul id="navigation-controller-main-wrapper-id" className='navigation-controller-main-wrapper'>
                <li className='navigation-item-main'>
                  <NavHashLink  onClick={handleMenuActionOff} smooth to="/home">Home</NavHashLink></li>
                {/* <li className='navigation-item-main' onClick={()=>handleNavItems('tools')}> */}
                <li id="navigation-tool-icon-action" onMouseEnter={()=>handleMouseEnterInTool('tools')} onMouseOut={()=>{handleMouseOutTool('tools')}} className='navigation-item-main'>
                  {/* Tools<FontAwesomeIcon icon={!toolOpen? faAngleDown:faAngleUp}/></li> */}
                  Tools</li>
                <li className='navigation-item-main'>
                  <NavHashLink  onClick={handleMenuActionOff} smooth to="/resources">Resources</NavHashLink></li>
                <li className='navigation-item-main'>
                  <NavHashLink  onClick={handleMenuActionOff} smooth to="/services">Services</NavHashLink></li>
                <li className='navigation-item-main'>
                  <NavHashLink  onClick={handleMenuActionOff} smooth to="/contact">Contact us</NavHashLink></li>
                <li className='navigation-item-main'>
                  <NavHashLink className="navigation-item-experience-wow-navhashlink"   onClick={handleExpWow} smooth to="#">Experience WOW</NavHashLink></li>
                <li id="navigator-item-main-sign-in-btn" className='navigation-item-main'>
                <ButtonOne text="Sign in" btnAction={()=>{console.log('signed in')}}/></li>
              </ul>
            </nav>
          </nav>
          <div className='navigation-bar-sign-in-wrapper'>
            <div id='navigation-bar-sign-in-btn'>
              {/* <ButtonOne text="Sign in" btnAction={()=>{console.log('signed in')}}/> */}
            </div>
            <div id='navigation-bar-menu-icon-btn'>
              <ButtonOne isOpen={isOpen} type={2} text="Open menu" btnAction={handleMenuAction}/>
            </div>
          </div>
        </div>
      </div>
      <div onMouseOver={()=>handleMouseEnterInTool('tools')} onMouseOut={()=>{handleMouseOutTool('tools')}}   id='navigation-bar-secondary-item-main-wrapper'  className='navigation-bar-secondary-item-main-wrapper'>
        {itemType === 'tools' && 
        <div className='navigation-bar-tools-drawer-items-container'>
          <h2>Coming Soon</h2>
        </div>
        }
      </div>
      </div>
  )
}

export default NavigationBar