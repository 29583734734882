import React from 'react';
import './SectionTitleOne.css';

const SectionTitleOne = ({type=1,text,rotY=-10,rotZ=10,ht=58,wt=106,leftD=0}) => {
    if(type == 2){
        return (
            <div className='section-outer-main-title-holder'>
                <div>
    
            <div className='shared-section-title-one-main-container'>
                <div className='shared-section-title-one-text-holder-type-two'>
                    <h2>{text}</h2>
                    <div style={{position:'absolute',left:`${leftD}%`,transform: `rotateZ(${rotZ}deg) rotateY(${rotY}deg)`,height:`${ht}px`,width:`${wt}px`}} className='shared-section-title-one-design-holder'></div>
                </div>
            </div>
            </div>
            </div>
        );
    }
    return (
        <div className='section-outer-main-title-holder'>
            <div>

        <div className='shared-section-title-one-main-container'>
            <div className='shared-section-title-one-text-holder'>
                <h2>{text}</h2>
                <div style={{position:'absolute',left:`${leftD}px`,transform: `rotateZ(${rotZ}deg) rotateY(${rotY}deg)`,height:`${ht}px`,width:`${wt}px`}} className='shared-section-title-one-design-holder'></div>
            </div>
        </div>
        </div>
        </div>
    );
};

export default SectionTitleOne;