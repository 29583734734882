import React from 'react';
import './ResourceCard.css';
import defaultImageOne from '../../../assets/images/pages-images/resources-page-images/resources-image-default.png'
import shareIconOne from '../../../assets/images/icons/share-icon-one.png'
import { NavHashLink } from 'react-router-hash-link';

const ResourceCard = ({cardInfo}) => {
    const {id,imageLink,resTitle,resDes,resType,resDuration,resPublished} = cardInfo
    return (
        <NavHashLink className="resource-card-outer-container-nav-link-container" to={`/resources/${id}`}>
        <div className='resource-card-main-container'>
            <div className='resource-card-image-holder'>
                <img src={imageLink} alt={`${resTitle}`}/>
            </div>
            <div className='resource-card-title-container'>
                <h3>{resTitle}</h3>
            </div>
            <div className='resource-card-description-container'>
                <p>{resDes}</p>
            </div>
            <div className='resource-card-bottom-info-holder'>
                <div className='resource-card-bottom-info-left-container'>
                    <div>
                        <h4>{resType}</h4>
                        <p>{resPublished.split(" ")[1]} {resPublished.split(" ")[2]} {resPublished.split(" ")[3]} {resPublished.split(" ")[4].split(":")[0]<12?resPublished.split(" ")[4].split(":")[0]:resPublished.split(" ")[4].split(":")[0]-12}:{resPublished.split(" ")[4].split(":")[1]} {resPublished.split(" ")[4].split(":")[0]<12?"AM":"PM"}</p>
                    </div>
                </div>
                <div className='resource-card-bottom-info-right-container'>
                    <p>{resDuration} read</p>
                    <div className='resource-card-bottom-info-right-share-icon-holder'>
                        <img src={shareIconOne} alt="share-it"/> 
                    </div>
                </div>
            </div>
        </div>
        </NavHashLink>
    );
};

export default ResourceCard;