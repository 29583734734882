import React, { useEffect, useState } from 'react';
import './HomePageSectionFourProgram.css';

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import { Navigation,Pagination,Scrollbar, A11y, Autoplay, Mousewheel,loop } from 'swiper';

import SectionTitleOne from '../Shared/SectionTitleOne/SectionTitleOne';

// imported images 
import programWheelImage from '../../assets/images/pages-images/home-page-images/program-sile-one.png'
import wheelImage from '../../assets/images/pages-images/home-page-images/wheel-icon-one.png'
import discoveryCallImageOne from '../../assets/images/pages-images/home-page-images/discover_exp_us.svg'

import programCardImageOne from '../../assets/images/pages-images/home-page-images/game-image-one.svg'
import programCardImageTwo from '../../assets/images/pages-images/home-page-images/data-image-one.svg'
import programCardImageThree from '../../assets/images/pages-images/home-page-images/neuro-image-one.svg'


import AboutButtonPrimary from '../buttons/AboutButtonPrimary/AboutButtonPrimary';

const programInfos = {
    1:{
        imageLink: programCardImageThree,
        title:'Neuroscience',
        summary:"We Understand Dopamine",
        adv :[
            "Dopamine is what makes humans smarter. Too much dopamine from serious games limits learnability by shifting the learners into “pleasure” mode, while lack of it impedes motivation and learning. Optimal learning needs the right combination of Dopamine, Glutamate, and GABA and we've got this recipe right for you.",
        ]
    },
    2:{
        imageLink: programCardImageTwo,
        title:'Data Science',
        summary:"We make the data speak for you",
        adv :[
            'Games are a goldmine of behavioural data. We capture vital data points and compile reports with  actionable insights, ready to be presented to the stakeholders. Demonstrate ROI and Level up your Game'
        ]
    },
    3:{
        imageLink: programCardImageThree,
        title:'Experience',
        summary:"We have experience designing 50+ serious games.",
        adv :[
            "From games on emotional vocabulary to diversity and inclusion, from change management to agile thinking, from balance sheets to strategic decision making, from team building to trust building, from organizational need analysis to intrinsic and extrinsic employee motivations, we have experience serving a diverse set of client needs.",
        ]
    },
    4:{
        imageLink: programCardImageOne,
        title:'Game Science',
        summary:"We are wired to learn via play, its evolutionary.",
        adv :[
            "We scientifically map learning objectives to game mechanics to embed the deliverables within a game that is also engaging and memorable. Our games are modelled around real world scenarios in a hypothetical storyline creating a perfect environment to experiment, fail, unlearn and relearn."
        ]
    },
}

const programInfosOne = {
    
    1:{
        imageLink: programCardImageOne,
        title:'Game Science',
        summary:"We are wired to learn via play, its evolutionary.",
        adv :[
            "We scientifically map learning objectives to game mechanics to embed the deliverables within a game that is also engaging and memorable. Our games are modelled around real world scenarios in a hypothetical storyline creating a perfect environment to experiment, fail, unlearn and relearn."
        ]
    },
    2:{
        imageLink: programCardImageTwo,
        title:'Data Science',
        summary:"We make the data speak for you",
        adv :[
            'Games are a goldmine of behavioural data. We capture vital data points and compile reports with  actionable insights, ready to be presented to the stakeholders. Demonstrate ROI and Level up your Game'
        ]
    },
    3:{
        imageLink: programCardImageThree,
        title:'Neuroscience',
        summary:"We Understand Dopamine",
        adv :[
            "Dopamine is what makes humans smarter. Too much dopamine from serious games limits learnability by shifting the learners into “pleasure” mode, while lack of it impedes motivation and learning. Optimal learning needs the right combination of Dopamine, Glutamate, and GABA and we've got this recipe right for you.",
        ]
    },
    4:{
        imageLink: programCardImageOne,
        title:'Experience',
        summary:"We have experience designing 50+ serious games.",
        adv :[
            "From games on emotional vocabulary to diversity and inclusion, from change management to agile thinking, from balance sheets to strategic decision making, from team building to trust building, from organizational need analysis to intrinsic and extrinsic employee motivations, we have experience serving a diverse set of client needs.",
        ]
    },
    
}

const HomePageSectionFourProgram = ({handleMenuActionAlternate}) => {
    const [wheelVal,setWheelVal] = useState(2);
    const [rotVal,setRotVal] = useState(45)
    const [changed,setChanged] = useState(true);

    const [visibleCardNo,setVisibleCardNo] = useState(3);

    const [cardIndexVal,setCardIndexVal] =  useState(1);
    
    useEffect(()=>{
        if(wheelVal === 2){
            setRotVal(45);
            return
        }
        else if(wheelVal === 3){
            setRotVal(315);
            return
        }
        else if(wheelVal === 4){
            setRotVal(225);
            return
        }
        else if(wheelVal === 1){
            setRotVal(135);
            return
        }
    },[wheelVal])

    useEffect(()=>{
        const myInter = setTimeout(()=>{
            if(wheelVal===1){
                setWheelVal(2)
            }else if(wheelVal === 2){
                setWheelVal(3)
            }else if(wheelVal === 3){
                setWheelVal(4)
            }else if(wheelVal === 4){
                setWheelVal(1)
            }
            setChanged(!changed)
        },30000)
        return () => clearInterval(myInter)
    },[changed])


    const handleSlideChange = (e) =>{
        if(e.realIndex === 3){
            setCardIndexVal(1)
        }else{
            setCardIndexVal(e.realIndex+2)
        }
    }

    const handleBookDiscoveryCall = () =>{
        window.open('https://calendly.com/sile_ai/quick-chat', '_blank');
    }
    return (
        <section  onClick={handleMenuActionAlternate} className='home-page-section-four-program-main-container'>
            <div className='home-page-section-four-program-main-title-outer-container'>
                <h2>The back-end team behind leading global training companies, Now at your service</h2>
                {/* <p>We were the chosen ones because</p> */}
            </div>
            <div className='home-page-section-four-program-inner-container'>
                {/* <div  className='home-page-main-title-container'>
                        <div className='home-section-four-title-container'></div>
                        <p className='home-section-four-title-en'>Program categories</p>
                </div> */}
                {/* <div style={{margin:'1vw'}}>
                    <SectionTitleOne rotY={-10} rotZ={-8} wt={210} text={"Program categories"}/>
                </div> */}
                <div className='home-page-section-four-program-information-container'>
                    <div className='home-page-program-info-left-wheel-inner-container'>
                        <div className='home-page-program-left-wheel-image-holder'>
                            <div className='home-page-program-left-wheel-image-holder-bg-one'>
                                <div className='home-page-program-wheel-icon-holder' style={{rotate:`${-0}deg`}}>
                                    <img src={wheelImage} alt="determiner"/>
                                </div>
                            </div>
                            <div style={{rotate:`${rotVal}deg`}} className='home-page-program-left-wheel-image-holder-bg-two'>
                                <div onClick={()=>setWheelVal(1)} style={{filter:`${wheelVal == 1?'':'grayscale(80%)'}`}} className='home-page-program-left-wheel-image-holder-main-one'>
                                    <p>Neuroscience</p>
                                </div>
                                <div onClick={()=>setWheelVal(2)}  style={{filter:`${wheelVal == 2?'':'grayscale(80%)'}`}} className='home-page-program-left-wheel-image-holder-main-two'>
                                    <p>Data Science</p>
                                </div>
                                <div  onClick={()=>setWheelVal(3)} style={{filter:`${wheelVal == 3?'':'grayscale(80%)'}`}} className='home-page-program-left-wheel-image-holder-main-three'>
                                    <p>Experience</p>
                                </div>
                                <div  onClick={()=>setWheelVal(4)} style={{filter:`${wheelVal == 4?'':'grayscale(80%)'}`}} className='home-page-program-left-wheel-image-holder-main-four'>
                                    <p>Game Science</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className='home-page-program-info-right-wheel-inner-container'>
                        <div className='home-page-program-info-right-info-inner-wrapper'>
                            <h2>{programInfos[wheelVal]['summary']}</h2>
                            <div className='home-page-program-info-right-info-details-wrapper'>
                                {programInfos[wheelVal]['adv'].map(singleInfo =>{
                                    return<p>{singleInfo}</p>
                                })}
                            </div>
                            <div className='home-page-program-info-right-info-button-holder'>
                                <div className='home-page-program-info-right-info-exp-text-holder'>
                                    <img src={discoveryCallImageOne} alt="discovery_experience_with_us"/>
                                </div>
                                <div className='home-page-program-info-right-info-primary-btn-holder'>
                                    <AboutButtonPrimary type={3} text={'Book a Discovery call'} btnAction={handleBookDiscoveryCall}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='home-page-section-four-program-inner-container-small'>
                <div className='home-page-section-four-program-inner-container-small-card-outer-container'>
                <Swiper 
                className='home-page-section-four-program-inner-container-small-card-swiper-outer-container'
                modules={[Navigation,Autoplay, Pagination, Scrollbar, A11y,Mousewheel]}
                spaceBetween={5}
                slidesPerView={3}
                direction="horizontal"
                mousewheel={false}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                navigation
                pagination={{ clickable: false }}
                scrollbar={{ draggable: false }}
                // onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={(e) => handleSlideChange(e)}
                onActiveIndexChange={(e)=>{console.log(e)}}
                >
                    {
                        Object.keys(programInfosOne).map((program)=>{
                        return (
                        <SwiperSlide style={{scale: cardIndexVal==program?'1.05':'0.95',zIndex: cardIndexVal==program?2:1,opacity: cardIndexVal==program?'1':'.7'}} className='home-page-section-four-small-card-image-container '>
                            <div className='home-page-section-four-small-card-image-container-inner'>
                                <img src={programInfosOne[program]['imageLink']} alt={programInfosOne[program]['title']}/>
                            </div>
                            <div className='home-page-section-four-small-card-text-container-inner'>
                                <p>{programInfosOne[program]['title']}</p>
                            </div>
                        </SwiperSlide>)}
                    )}
                </Swiper>
                </div>
                <div className='home-page-section-four-small-card-info-container'>
                    <h2>{programInfosOne[cardIndexVal]["summary"]}</h2>
                    <p>{programInfosOne[cardIndexVal]["adv"]}</p>
                </div>
                <div className='home-page-program-info-right-info-button-holder'>
                                <div className='home-page-program-info-right-info-exp-text-holder'>
                                    <img src={discoveryCallImageOne} alt="discovery_experience_with_us"/>
                                </div>
                                <div className='home-page-program-info-right-info-primary-btn-holder'>
                                    <AboutButtonPrimary type={3} text={'Book a Discovery call'} btnAction={handleBookDiscoveryCall}/>
                                </div>
                            </div>
            </div>
        </section>
    );
};

export default HomePageSectionFourProgram;