import React from 'react';
import './HomeTitlePrimary.css';

const HomeTitlePrimary = ({text,posX}) => {
    return (
        <h2 className='shared-home-title-primary-main-container'>
            {text}
        </h2>
    );
};

export default HomeTitlePrimary;