import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAligZbwi1je6AuTRE7di-2TcWwRrSGkwE",
  authDomain: "sile-afd70.firebaseapp.com",
  projectId: "sile-afd70",
  storageBucket: "sile-afd70.appspot.com",
  messagingSenderId: "590254401083",
  appId: "1:590254401083:web:a3a42227dcfbe7316f9e1e",
  measurementId: "G-X6MR3LHKNK"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const auth = getAuth();
export const database = getDatabase(firebaseApp);
export const databaseF = getFirestore(firebaseApp)
export const storage = getStorage(firebaseApp);

export var cloudFunctionURL;

if (window.location.hostname == "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectDatabaseEmulator(database, "localhost", 9000);
  connectStorageEmulator(storage, "localhost", 9198);
  connectFirestoreEmulator(databaseF, 'localhost', 8080);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
