import React, { useState } from 'react';
import './PopUpOne.css';

import closeIconOne from '../../../assets/images/icons/close_icon_one.svg'
import AboutButtonPrimary from '../../buttons/AboutButtonPrimary/AboutButtonPrimary';
import { addDoc, collection, getFirestore, Timestamp } from 'firebase/firestore';

// imported images
import popTypeFiveImageOne from '../../../assets/images/pages-images/pop-up-images/newsletter-pop-up-image-one.svg'

import popTypeSixImageOne from '../../../assets/images/pages-images/pop-up-images/pop-up-type-six-bg-image.svg';

import bottomImageOne from '../../../assets/images/backgrounds/top-color-mixture-1.png'


const popUpText = {
    0:"Welcome to",
    1:"Our team will get back to you as soon as we review your message.",
    // 1:"Thank you for subscribing to next gen immersive learning experience solutions. You'll hear back from us soon",
    2:"Sorry, there was an error. Please try again later.",
    3:"Please enter an valid email.",
    4:[
        "Sorry, all existing slots are booked",
        "Drop Your email and we will reach out to you immediately on opening new session slots."
    ],
    5:[
        "Welcome to the SILE Facilitator community",
        "You have registered successfully"
    ],
    6:[
        "You are one step closer to WOW!",
        "Your gift is on its way!"
    ],
    7: "Please enter an valid name",
    8: "Please enter an valid concern"
}


const PopUpOne = ({handlePopUp,popUpType,handleClosePopUpBtn}) => {

    
    const storeDb = getFirestore()

    const [subEmail,setSubEmail] = useState('');

    const handleSubscriber = async () =>{
        if(!subEmail || (!subEmail.includes('@'))){
            // alert("please give a valid email.")
            handlePopUp(3)
            setSubEmail("");
            return
        }
        else{
            
            // const cityRef = doc(storeDb, 'sile', `subscribers`);
            const subRef = collection(storeDb, 'subscribers');
            addDoc(subRef,{
                email:subEmail,
                time: Timestamp.fromDate(new Date())
            })
            .then(()=>{
                console.log('data added')
                handlePopUp(1) // 1->successful pop up type
            })
            .then(()=>{
                handlePopUp(1)
            })
            .catch(err=>{ 
                console(err.message)
                handlePopUp(3) // -1 -> unsuccessful pop up type
            })
            
            
            // const myData = {
            //     email: subEmail
            // }
            // await addDoc(doc(storeDb,subRef), myData);
            setSubEmail("");
        }
    }

        if(popUpType === 6){
            return(
                <div className='pop-up-one-main-container'>
                <div style={{position:'absolute',height:'100vh',width:'100vw'}} onClick={()=>handleClosePopUpBtn()}></div>
                <div className='pop-up-one-inner-wrapper-black'>
                    <div className='pop-up-one-close-btn-outer-container'>
                        <div onClick={()=>handleClosePopUpBtn()} className='pop-up-one-close-btn-container-grey'>
                            <img src={closeIconOne} alt="close-it"/>
                        </div>
                    </div>
                    <div className='pop-up-one-inner-information-container'>
                        <div className='pop-up-one-type-five-inner-image-holder'>
                            <img src={popTypeSixImageOne} alt="successfully added"/>
                        </div>
                    <div className='pop-up-one-type-five-inner-text-holder'>
                        <h2 className='pop-up-one-type-five-inner-text-holder-h2'>{popUpText[6][0]}</h2>
                        <p className='pop-up-one-type-five-inner-text-holder-p'>{popUpText[6][1]}</p>
                    </div>
                    </div>
                    <div className='pop-up-container-bottom-border-image-holder'>
                        <img src={bottomImageOne} alt="pop-up"/>
                    </div>
                </div>
            </div>
            )
            
        }
        if(popUpType === 5){
            return(
                <div className='pop-up-one-main-container'>
                <div style={{position:'absolute',height:'100vh',width:'100vw'}} onClick={()=>handleClosePopUpBtn()}></div>
                <div className='pop-up-one-inner-wrapper'>
                    <div className='pop-up-one-close-btn-outer-container'>
                        <div onClick={()=>handleClosePopUpBtn()} className='pop-up-one-close-btn-container'>
                            <img src={closeIconOne} alt="close-it"/>
                        </div>
                    </div>
                    <div className='pop-up-one-inner-information-container'>
                        <div className='pop-up-one-type-five-inner-image-holder'>
                            <img src={popTypeFiveImageOne} alt="successfully added"/>
                        </div>
                    <div className='pop-up-one-type-five-inner-text-holder'>
                        <h2 className='pop-up-one-type-five-inner-text-holder-h2'>{popUpText[5][0]}</h2>
                        <p className='pop-up-one-type-five-inner-text-holder-p'>{popUpText[5][1]}</p>
                    </div>
                    </div>
                </div>
            </div>
            )
            
        }



        return (
            <div className='pop-up-one-main-container'>
                <div style={{position:'absolute',height:'100vh',width:'100vw'}} onClick={()=>handleClosePopUpBtn()}></div>
                <div className='pop-up-one-inner-wrapper'>
                    <div className='pop-up-one-close-btn-outer-container'>
                        <div onClick={()=>handleClosePopUpBtn()} className='pop-up-one-close-btn-container'>
                            <img src={closeIconOne} alt="close-it"/>
                        </div>
                    </div>
                    {popUpType===1 && <div className='pop-up-one-inner-message-main-holder'>
                        <h2>{popUpText[1]}</h2>
                        <h1>Thank You!</h1>
                    </div>}
                    {popUpType===2 && <div className='pop-up-one-inner-message-main-holder-red'>
                        <h2>{popUpText[2]}</h2>
                        <h1>Thank You!</h1>
                    </div>}
                    {popUpType===3 && <div className='pop-up-one-inner-message-main-holder-red'>
                        <h2>{popUpText[3]}</h2>
                        <h1>Thank You!</h1>
                    </div>}
                    {popUpType===4 && <div className='pop-up-one-inner-message-main-holder-wow'>
                        <h2 className='pop-up-one-inner-message-main-holder-wow-title-one'>{popUpText[4][0]}</h2>
                        <h1>{popUpText[4][1]}</h1>
                        <div className='pop-up-one-inner-message-main-holder-wow-email-input-container'>
                            <input onChange={e=>setSubEmail(e.target.value)} value={subEmail} type="email" placeholder="Your email address"/>
                            <div style={{width:'146px',height:'44px'}}>
                                <AboutButtonPrimary type={5} text="Prioritise me" btnAction={()=>handleSubscriber()}/>
                            </div>
                        </div>
                    </div>}
                    {popUpType===7 && <div className='pop-up-one-inner-message-main-holder-red'>
                        <h2>{popUpText[7]}</h2>
                        <h1>Thank You!</h1>
                    </div>}
                    {popUpType===8 && <div className='pop-up-one-inner-message-main-holder-red'>
                        <h2>{popUpText[8]}</h2>
                        <h1>Thank You!</h1>
                    </div>}
                </div>
            </div>
        );
    // return (
    //     <div className='pop-up-one-main-container'>
    //         <div style={{position:'absolute',height:'100vh',width:'100vw'}} onClick={()=>handleClosePopUpBtn()}></div>
    //         <div className='pop-up-one-inner-wrapper'>
    //             <div className='pop-up-one-close-btn-outer-container'>
    //                 <div onClick={()=>handleClosePopUpBtn()} className='pop-up-one-close-btn-container'>
    //                     <img src={closeIconOne} alt="close-it"/>
    //                 </div>
    //             </div>
    //             {popUpType === 1 && <div>
    //                 <h2>{popUpText[0]} <a href='#' target="_blank">Sile.ai</a></h2>
    //                 <p>{popUpText[1]}</p>
    //             </div>}
    //             {popUpType === -1 && <div>
    //                 <h2>{popUpText[0]} <a href='#' target="_blank">Sile.ai</a></h2>
    //                 <p>{popUpText[2]}</p>
    //             </div>}
    //             {popUpType === -2 && <div>
    //                 <h2>{popUpText[0]} <a href='#' target="_blank">Sile.ai</a></h2>
    //                 <p>{popUpText[3]}</p>
    //             </div>}
    //         </div>
    //     </div>
    // );
};

export default PopUpOne;