
const GetRandomNumber = (max) => {
    return Math.floor(Math.random() * max);
};

const GetRandomNumberMultilple = (total,max) => {
    const tempArr = []
    for(let i=0;i<total;i++){
        if(tempArr.length <1){
            let num = Math.floor(Math.random() * max);
            tempArr.push(num);
        }
        else{
            let notPushed = true;
            while(notPushed){
                let numO = Math.floor(Math.random() * max);
                if(!tempArr.includes(numO)){
                    tempArr.push(numO)
                    notPushed = false;
                }
            }
            
        }
    }
    return tempArr;
};

export { GetRandomNumber,GetRandomNumberMultilple };