import React from 'react';
import './ServicesPageLanding.css'

import landingImageOne from '../../../assets/images/pages-images/services-page-images/services-landing-image-one.png'
import landingImageTwo from '../../../assets/images/pages-images/services-page-images/services-landing-image-two.png'

const ServicesPageLanding = () => {
    return (
        <div className='service-page-landing-main-container'>
            <div className='service-page-landing-inner-container'>
                <div className='service-page-landing-inner-left-wrapper'>
                    <div className='service-page-landing-inner-left-image-holder'>
                        <img src={landingImageOne} alt="data-driven-games-tailor-made for your organizational needs"/>
                    </div>
                </div>
                <div className='service-page-landing-inner-right-wrapper'>
                    <div className='service-page-landing-inner-right-image-holder'>
                        <img src={landingImageTwo} alt="service logic "/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesPageLanding;