import React, { useEffect, useState } from 'react';
import './ServicesAllCardContainer.css'
import useServices from '../../../hooks/useServices';
import HomeTitlePrimary from '../../Shared/HomeTitlePrimary/HomeTitlePrimary';
import SectionTitleOne from '../../Shared/SectionTitleOne/SectionTitleOne';
import ServiceCard from '../ServiceCard/ServiceCard';

const servicesTitleText = "Choose the genre of game you wish to build"

const ServicesAllCardContainer = () => {
    const [myServices,setMyServices] = useState({})
    const allServices = useServices
    useEffect(()=>{
        if(allServices){
            console.log(Object.entries(allServices.servicesData))
            setMyServices(allServices.servicesData)
        }
    },[])
    return (
        <div className='services-all-card-container-main-container'>
            <SectionTitleOne rotY={10} rotZ={-10} wt={148} ht={66} leftD={-20} text={"Our Services"}/>
            <HomeTitlePrimary text={servicesTitleText}/>
            <div className='services-all-card-container-main-card-holder'>
                {Object.entries(myServices).map((ser,index)=>
                    <ServiceCard key={`service-card-${index}`} cardTitle={ser[0]} bgImage={ser[1]["bgImage"]} cardInfos={ser[1]["allData"]}/>
                )}
            </div>
        </div>
    );
};

export default ServicesAllCardContainer;