import React from 'react';
import './ToolCard.css';

const ToolCard = ({text="",imageLink,textColor="#000000",shadowColor="#FFFFFF"}) => {
    return (
        <div style={{'--shadowColor':shadowColor,'--textColor':textColor}} className='tool-card-main-container'>
            <div style={{'--textColor':textColor}} className='tool-card-inner-image-holder'>
                <img src={imageLink} alt={text}/>
            </div>
            <div style={{'--shadowColor':shadowColor,'--textColor':textColor}} className='tool-card-inner-text-wrapper'>
                <p>{text}</p>
            </div>
        </div>
    );
};

export default ToolCard;