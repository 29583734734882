import React, { useEffect, useState } from 'react';
import useServices from '../../../hooks/useServices';
import SectionTitleOne from '../../Shared/SectionTitleOne/SectionTitleOne';
import './ServicesAllPastProjectsContainer.css'

const ServicesAllPastProjectsContainer = () => {
    const [myProjects,setMyProjects] = useState({})
    const allServices = useServices
    useEffect(()=>{
        if(allServices){
            console.log(Object.entries(allServices.pastProjectsData))
            setMyProjects(allServices.pastProjectsData)
        }
    },[])
    return (
        <div className='services-all-past-projects-container-main-container'>
            <SectionTitleOne rotY={10} rotZ={-10} wt={148} ht={66} leftD={-20} text={"Past Projects"}/>
            <div className='services-all-past-projects-inner-card-container'>
                {
                    Object.entries(myProjects).map((proj,index) => 
                            <div className='service-single-past-project-card-main-container'>
                                <div className='service-single-past-project-card-image-holder'>
                                    <img  src={require(`../../../assets/images/pages-images/services-page-images/past-projects/past_project_image_${proj[1]["imageId"]}.png`)} alt=""/>
                                </div>
                            </div>
                        
                    )
                }
            </div>
        </div>
    );
};

export default ServicesAllPastProjectsContainer;