import React, { useEffect, useState } from 'react';
import './ResourcesNav.css';

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import { Navigation,Pagination,Scrollbar, A11y, Autoplay, Mousewheel } from 'swiper';

const ResourcesNav = ({resTypes,setActiveResType, activeResType="All Resources"}) => {

    const [visibleCardNo,setVisibleCardNo] = useState(1);

    useEffect(()=>{
        const myDiv = document.getElementById('resources-nav-main-container-id')
        const myDivSize = myDiv.getBoundingClientRect();
        const tempN = myDivSize.width
        const finalNum = Math.max(1,Math.floor(tempN/200));
        console.log(finalNum);
        setVisibleCardNo(finalNum)
        
    },[])
    window.addEventListener('onload',e=>{
        const myDiv = document.getElementById('resources-nav-main-container-id')
        const myDivSize = myDiv.getBoundingClientRect();
        const tempN = myDivSize.width
        const finalNum = Math.max(1,Math.floor(tempN/200));
        console.log(finalNum);
        setVisibleCardNo(finalNum)
    })
    window.addEventListener('resize',e=>{
        const myDiv = document.getElementById('resources-nav-main-container-id')
        const myDivSize = myDiv.getBoundingClientRect();
        const tempN = myDivSize.width
        const finalNum = Math.max(1,Math.floor(tempN/200));
        console.log(finalNum);
        setVisibleCardNo(finalNum)
    })


    const handleResourcesCards = (resType) =>{
        setActiveResType(resType)
    }


    return (
        <div className='resources-nav-main-container' id='resources-nav-main-container-id'>
            <Swiper className='resources-nav-all-resource-type-main-holder'
                modules={[Navigation,Autoplay, Pagination, Scrollbar, A11y,Mousewheel]}
                spaceBetween={5}
                slidesPerView={visibleCardNo}
                direction="horizontal"
                mousewheel={true}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                >
                    {resTypes.map((res,index)=>{
                        return(
                            <SwiperSlide style={{width:'200px',height:'100%'}} className='home-page-eight-swiper-slide-container'>
                                <div key={index} onClick={()=>handleResourcesCards(res)} className={activeResType===res?'resources-nav-single-resources-container':'resources-nav-single-resources-container resources-nav-single-resources-container-hover '}>
                                    <p style={{color:`${activeResType===res?"#FE5C0B":"#939095"}`,fontWeight:`${activeResType===res?700:400}`,fontSize:`${activeResType===res?"18px":"16px"}`,lineHeight:`${activeResType===res?"35px":"30px"}`}}>{res}</p>
                                </div>
                            </SwiperSlide>
                        )
                    })}

                </Swiper>
            {/* {resTypes.map((res,index) => 
                <div key={index} onClick={()=>handleResourcesCards(res)} className='resources-nav-single-resources-container'>
                    <p style={{color:`${activeResType===res?"#FE5C0B":"#939095"}`,fontWeight:`${activeResType===res?700:400}`,fontSize:`${activeResType===res?"18px":"16px"}`,lineHeight:`${activeResType===res?"35px":"30px"}`}}>{res}</p>
                </div>
            )}            
            {resTypes.map((res,index) => 
                <div key={index} onClick={()=>handleResourcesCards(res)} className='resources-nav-single-resources-container'>
                    <p style={{color:`${activeResType===res?"#FE5C0B":"#939095"}`,fontWeight:`${activeResType===res?700:400}`,fontSize:`${activeResType===res?"18px":"16px"}`,lineHeight:`${activeResType===res?"35px":"30px"}`}}>{res}</p>
                </div>
            )}            
            {resTypes.map((res,index) => 
                <div key={index} onClick={()=>handleResourcesCards(res)} className='resources-nav-single-resources-container'>
                    <p style={{color:`${activeResType===res?"#FE5C0B":"#939095"}`,fontWeight:`${activeResType===res?700:400}`,fontSize:`${activeResType===res?"18px":"16px"}`,lineHeight:`${activeResType===res?"35px":"30px"}`}}>{res}</p>
                </div>
            )}             */}
        </div>
    );
};

export default ResourcesNav;