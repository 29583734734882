import React from 'react';
import './EmpowerCard.css';

// imported image
import randomImageOne from '../../../assets/images/pages-images/home-page-images/empower-bottom-random-image-one.png'

const EmpowerCard = ({cardTitle,cardImage}) => {
    // const {cardTitle, imageLink} = cardInfo
    return (
        <div className='empower-card-main-container'>
            <div className='empower-card-inner-top-image-holder'>
                <img src={cardImage} alt={`${cardTitle}`} />
            </div>
            <div className='empower-card-inner-text-container'>
                <h2>{cardTitle}</h2>
            </div>
            <div className='empower-card-inner-bottom-image-holder'>
                <img src={randomImageOne} alt="random"/>
            </div>
        </div>
    );
};

export default EmpowerCard;