import React, { useEffect, useState } from 'react';
import GetInTouchForm from '../../components/getInTouchComponents/GetInTouchForm/GetInTouchForm';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import PopUpOne from '../../components/popUps/PopUpOne/PopUpOne';
import Footer from '../../components/Shared/Footer/Footer';
import './GteInTouchPage.css'

const timeouts = []

const GetInTouchPage = () => {
    const [isOpen,setIsOpen] = useState(false);
    const [isLarge,setIsLarge] = useState(false);

    const[isPopUpOpen,setIsPopUpOpen] = useState(false);
    const[popUpType,setPopUpType] = useState(0);


    const handlePopUp = (type) =>{
        setPopUpType(type)
        setIsPopUpOpen(true)
        if(type !== 4){
            const myTimeOut = setTimeout(()=>{
                console.log('timedOut')
                setIsPopUpOpen(false)
                setPopUpType(0)
            },4000)
            timeouts.push(myTimeOut)
            return () => clearTimeout(myTimeOut)
        }
    }

    const handleClosePopUpBtn = () =>{
        setIsPopUpOpen(false)
        setPopUpType(0)
        for(let i=0;i<timeouts.length;i++){
            clearTimeout(timeouts[i])
        }
    }
    
    const handleMenuActionAlternate = ()=>{
        const myDiv = document.getElementById('navigation-controller-main-wrapper-id')
        if(isOpen){
            myDiv.style.right='-100vw';
            setIsOpen(false);
        }
    }
    
    useEffect(()=>{
        const myDiv = document.getElementById('get-in-touch-main-container')
        const myDivSize = myDiv.getBoundingClientRect();
        const tempN = myDivSize.width
        if(tempN>801){
            setIsOpen(false);
            handleMenuActionAlternate()
            setIsLarge(true)
        }
        else{
            setIsLarge(false)
        }
    },[])

    window.addEventListener('resize',e=>{
        const myDiv = document.getElementById('get-in-touch-main-container')
        const myDivSize = myDiv.getBoundingClientRect();
        const tempN = myDivSize.width
        if( isOpen && tempN>801){
            setIsOpen(false);
            handleMenuActionAlternate()
        }
        if(tempN<=801){
            setIsLarge(false)
        }
        else{
            setIsLarge(true)
        }
    })
    return (
        <div id="get-in-touch-main-container" className='get-in-touch-page-main-container'>
            <div style={{position:'absolute'}}>
                <NavigationBar handlePopUp={handlePopUp} isOpen={isOpen} setIsOpen={setIsOpen}/>
            </div>
            <div onClick={()=>handleMenuActionAlternate()} className='shared-main-window-page-bottom-container'>
            {isPopUpOpen && popUpType!== 0 && <PopUpOne popUpType={popUpType} handleClosePopUpBtn={handleClosePopUpBtn} handlePopUp={handlePopUp} />}
                <GetInTouchForm handlePopUp={handlePopUp} />
                <Footer handlePopUp={handlePopUp}/>
            </div>
        </div>
    );
};

export default GetInTouchPage;