import React, { useEffect, useState } from 'react';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import PopUpOne from '../../components/popUps/PopUpOne/PopUpOne';
import ServicesAllCardContainer from '../../components/servicesComponents/ServicesAllCardContainer/ServicesAllCardContainer';
import ServicesAllPastProjectsContainer from '../../components/servicesComponents/ServicesAllPastProjectsContainer/ServicesAllPastProjectsContainer';
import ServicesPageLanding from '../../components/servicesComponents/ServicesPageLanding/ServicesPageLanding';
import Footer from '../../components/Shared/Footer/Footer';
import HomeTitlePrimary from '../../components/Shared/HomeTitlePrimary/HomeTitlePrimary';
import HomeTitleSecondary from '../../components/Shared/HomeTitleSecondary/HomeTitleSecondary';
import SectionTitleOne from '../../components/Shared/SectionTitleOne/SectionTitleOne';
import SharedHostCallBookContainer from '../../components/Shared/SharedHostCallBookContainer/SharedHostCallBookContainer';
import './ServicesPage.css';

const timeouts = []

const ServicesPage = () => {
    const [isOpen,setIsOpen] = useState(false);
    const [isLarge,setIsLarge] = useState(false);

    const[isPopUpOpen,setIsPopUpOpen] = useState(false);
    const[popUpType,setPopUpType] = useState(0);

    const handlePopUp = (type) =>{
        setPopUpType(type)
        setIsPopUpOpen(true)
        if(type !== 4){
            const myTimeOut = setTimeout(()=>{
                console.log('timedOut')
                setIsPopUpOpen(false)
                setPopUpType(0)
            },4000)
            timeouts.push(myTimeOut)
            return () => clearTimeout(myTimeOut)
        }
    }

    const handleClosePopUpBtn = () =>{
        setIsPopUpOpen(false)
        setPopUpType(0)
        for(let i=0;i<timeouts.length;i++){
            clearTimeout(timeouts[i])
        }
    }
    
    const handleMenuActionAlternate = ()=>{
        const myDiv = document.getElementById('navigation-controller-main-wrapper-id')
        if(isOpen){
            myDiv.style.right='-100vw';
            setIsOpen(false);
        }
    }
    
    useEffect(()=>{
        const myDiv = document.getElementById('services-main-container')
        const myDivSize = myDiv.getBoundingClientRect();
        const tempN = myDivSize.width
        if(tempN>801){
            setIsOpen(false);
            handleMenuActionAlternate()
            setIsLarge(true)
        }
        else{
            setIsLarge(false)
        }
    },[])

    window.addEventListener('resize',e=>{
        const myDiv = document.getElementById('services-main-container')
        const myDivSize = myDiv.getBoundingClientRect();
        const tempN = myDivSize.width
        if( isOpen && tempN>801){
            setIsOpen(false);
            handleMenuActionAlternate()
        }
        if(tempN<=801){
            setIsLarge(false)
        }
        else{
            setIsLarge(true)
        }
    })

    const handleBookDiscoveryCall = () =>{
        window.open('https://calendly.com/sile_ai/quick-chat', '_blank');
    }

    return (
        <div id="services-main-container" className='services-page-main-container'>
            <div style={{position:'absolute'}}>
                <NavigationBar handlePopUp={handlePopUp} isOpen={isOpen} setIsOpen={setIsOpen}/>
            </div>
            <div onClick={()=>handleMenuActionAlternate()} className='shared-main-window-page-bottom-container'>
            {isPopUpOpen && popUpType!== 0 && <PopUpOne popUpType={popUpType} handleClosePopUpBtn={handleClosePopUpBtn} handlePopUp={handlePopUp} />}
                <ServicesPageLanding/>
                <ServicesAllCardContainer/>
                <SharedHostCallBookContainer text={"Still unsure about what you need?"} btnAction={handleBookDiscoveryCall} btnVal={"Book a Free Consultation call"}/>
                <ServicesAllPastProjectsContainer/>
                <Footer handlePopUp={handlePopUp}/>
            </div>
        </div>
    );
};

export default ServicesPage;