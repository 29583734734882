const useServices = {
    servicesData:{
        Games: {
            bgImage: 0,
            allData:
                {
                    0:{
                        servicesDes:"Games on Company Culture",
                        iconNum:0
                    },
                    1:{
                        servicesDes:"Virtual Events",
                        iconNum:1
                    },
                    2:{
                        servicesDes:"Game Driven Training",
                        iconNum:2
                    },
                    3:{
                        servicesDes:"Games on Company Culture",
                        iconNum:3
                    },
                    4:{
                        servicesDes:"Off-Site Games",
                        iconNum:4
                    },                },
        },
        Simulation: {
            bgImage: 1,
            allData: {
                0:{
                    servicesDes:"Scenario Based",
                    iconNum:5
                },
                1:{
                    servicesDes:"Story Based",
                    iconNum:6
                },
                2:{
                    servicesDes:"Knowledge Transfer",
                    iconNum:7
                },
                3:{
                    servicesDes:"Hiring and Onboarding",
                    iconNum:8
                },
                4:{
                    servicesDes:"Live Data Driven Simulation",
                    iconNum:9
                },
            },
        },
        Gamification: {
            bgImage: 2,
            allData: {
                0:{
                    servicesDes:"Process Gamification",
                    iconNum:10
                },
                1:{
                    servicesDes:"Process Gamification",
                    iconNum:11
                },
                2:{
                    servicesDes:"Course Gamification",
                    iconNum:12
                },
                
            },
        },

        "Everything Game Based": {
            bgImage: 3,
            allData: {
                0:{
                    servicesDes:"Escape Room",
                    iconNum:13
                },
                1:{
                    servicesDes:"Culture Assessments",
                    iconNum:14
                },
                2:{
                    servicesDes:"Behaviour Assessments",
                    iconNum:15
                },
                3:{
                    servicesDes:"Behaviour Assessments",
                    iconNum:16
                },
                4:{
                    servicesDes:"Compliance",
                    iconNum:17
                },
            },
        },
    },
    pastProjectsData:{
        "Just In Jungle":{
            imageId: 0,
            projectLink: "#"
        },
        "Hustle":{
            imageId: 1,
            projectLink: "#"
        },
        "project 3":{
            imageId: 2,
            projectLink: "#"
        },
        "Emogi":{
            imageId: 3,
            projectLink: "#"
        },
        "project 5":{
            imageId: 4,
            projectLink: "#"
        },
        "project 6":{
            imageId: 5,
            projectLink: "#"
        },
        "project 7":{
            imageId: 6,
            projectLink: "#"
        },
        "project 8":{
            imageId: 7,
            projectLink: "#"
        },
        "project 9":{
            imageId: 8,
            projectLink: "#"
        },
        "project 10":{
            imageId: 9,
            projectLink: "#"
        },
        "project 11":{
            imageId: 10,
            projectLink: "#"
        },
        "project 12":{
            imageId: 11,
            projectLink: "#"
        },
        "project 13":{
            imageId: 12,
            projectLink: "#"
        },
        "project 14":{
            imageId: 13,
            projectLink: "#"
        },
        "project 15":{
            imageId: 14,
            projectLink: "#"
        },
    }
}

export default useServices;