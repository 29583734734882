import React, { useEffect } from 'react';
import './ResourcesCardContainer.css';
import ResourceCard from '../../cards/ResourceCard/ResourceCard';

// imageLink=defaultImageOne,resTitle="",resDes="",resType="",resDuration="",resPublished=""

// const cardsInfo = [
//     {
//         imageLink: resourceImageOne,
//         resTitle:"Increase engagement and learning transfer with principles from game design.",
//         resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
//         resType:"ATD Article",
//         resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
//         resDuration:"2 mins"
//     },
//     {
//         imageLink: resourceImageTwo,
//         resTitle:"Increase engagement and learning transfer with principles from game design.",
//         resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
//         resType:"ATD Article",
//         resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
//         resDuration:"2 mins"
//     },
//     {
//         imageLink: resourceImageThree,
//         resTitle:"Increase engagement and learning transfer with principles from game design.",
//         resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
//         resType:"ATD Article",
//         resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
//         resDuration:"2 mins"
//     },
//     {
//         imageLink: resourceImageOne,
//         resTitle:"Increase engagement and learning transfer with principles from game design.",
//         resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
//         resType:"ATD Article",
//         resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
//         resDuration:"2 mins"
//     },
//     {
//         imageLink: resourceImageTwo,
//         resTitle:"Increase engagement and learning transfer with principles from game design.",
//         resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
//         resType:"ATD Article",
//         resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
//         resDuration:"2 mins"
//     },
//     {
//         imageLink: resourceImageThree,
//         resTitle:"Increase engagement and learning transfer with principles from game design.",
//         resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
//         resType:"ATD Article",
//         resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
//         resDuration:"2 mins"
//     },
// ]

const ResourcesCardContainer = ({total=0,cardsInfo=[]}) => {
    if(total !== 0){
        return (
            <div className='resources-card-container-main-container'>
                {/* <ResourceCard cardInfo={cardsInfo[0]}/> */}
                {cardsInfo.map((singleCard,index)=>{
                    if(index<total){
                        return(<ResourceCard key={index} cardInfo={singleCard}/>)
                    }}
                )}
            </div>
        );

    }
    return (
        <div className='resources-card-container-main-container'>
            {/* <ResourceCard cardInfo={cardsInfo[0]}/> */}
            {cardsInfo.map((singleCard,index)=>
                <ResourceCard key={index} cardInfo={singleCard}/>
            )}
        </div>
    );
};

export default ResourcesCardContainer;