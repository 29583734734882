import React, { useEffect, useState } from 'react';
import MainButtonsOne from '../buttons/MainButtonsOne/MainButtonsOne';
import './HomePageSectionTwo.css'

import homeImageThree from '../../assets/images/backgrounds/home-image-three.png'
import dataDrivenImageTwo from '../../assets/images/pages-images/home-page-images/data-driven-image-two.png'
import dataDrivenImageThree from '../../assets/images/pages-images/home-page-images/data-driven-image-three.svg'

import shapeOne from '../../assets/images/shapes/shape-circle-yellow-one.png'
import shapeTwo from '../../assets/images/shapes/shape-half-circle-border-green-one.png'
import shapeThree from '../../assets/images/shapes/shape-square-one.png'
import shapeFour from '../../assets/images/shapes/shape-square-red-one.png'
import shapeFive from '../../assets/images/shapes/shape-circle-lightblue-one.png'
import shapeSix from '../../assets/images/shapes/shape-rect-lightgreen-two.png'
import shapeSeven from '../../assets/images/shapes/shape-half-circle-border-purple-one.png'
import shapeEight from '../../assets/images/shapes/shape-triangle-blue-one.png'
import shapeNine from '../../assets/images/shapes/shape-rect-one.png'

import toolImageOne from '../../assets/images/pages-images/home-page-images/tools-image/four-plants-img-one.svg';
import toolImageTwo from '../../assets/images/pages-images/home-page-images/tools-image/motivus-img-one.svg';
import demoImageOne from '../../assets/images/pages-images/home-page-images/product_demo.svg'


import NumberShowcase from '../NumberShowcase/NumberShowcase';
import HomePageSectionNineOurClients from '../HomePageSectionNineOurClients/HomePageSectionNineOurClients';
import AboutButtonPrimary from '../buttons/AboutButtonPrimary/AboutButtonPrimary';
import ToolCard from '../cards/ToolCard/ToolCard';

// text="",imageLink,textColor="#000000",shadowColor="#FFFFFF"
const toolInfos = [
    {
        text:"4 Plants",
        imageLink: toolImageOne,
        textColor: "#00CE83",
        shadowColor: "rgba(0, 206, 131, 0.2)",
    },
    {
        text:"Motivus",
        imageLink: toolImageTwo,
        textColor: "#34BAE5",
        shadowColor: "rgba(52, 186, 229, 0.2)",
    },
]

const sectionTwoInfos = [
    {
        total: 387,
        text:'Facilitated training hours'
    },
    {
        total: 12,
        text:'Organisations enrolled with our pilot'
    },
    {
        total: 387,
        text:'Our NPS score across our programs'
    },
    {
        total: 237,
        text:'Facilitated hours'
    },
    {
        total: 1252,
        text:'enrolled with our pilot'
    },
    {
        total: 87,
        text:'Our across our programs'
    },
]

const HomePageSectionTwo = ({handlePopUp,handleMenuActionAlternate}) => {
    const [numbers,setNumbers] = useState(0)

    useEffect(()=>{
        const myInterval = setInterval(()=>{
            if(numbers === 0){
                setNumbers(3)
            }
            else{
                setNumbers(0)
            }
        },5000)
        return () => clearInterval(myInterval)
    },[])

    const handleBookDiscoveryCall = () =>{
        window.open('https://calendly.com/sile_ai/quick-chat', '_blank');
    }


    
    return (
        <section  onClick={handleMenuActionAlternate} id="home-page-section-two" className='home-page-section-two-main-container'>
            <div className='home-page-section-two-inner-container'>
                <div className='home-page-section-two-inner-left-container'>
                    <div className='home-page-section-two-title-container'>
                        <p>Tools designed to create a lasting impact. Provide the ROI your clients are looking for.</p>
                        <div></div>
                    </div>
                    <div className='home-page-section-two-description-one'>
                        <h2>We believe that the best way to understand our products and services, is to <span className='home-page-section-two-description-one-span-text-experience'>experience it for yourself</span></h2>
                    </div>
                    <div className='home-page-section-two-description-two'>
                        {/* text="",imageLink,textColor="#000000",shadowColor="#FFFFFF" */}
                    <ToolCard text={toolInfos[0]["text"]} imageLink={toolInfos[0]['imageLink']} textColor={toolInfos[0]['textColor']} shadowColor={toolInfos[0]['shadowColor']}/>
                    <ToolCard text={toolInfos[1]["text"]} imageLink={toolInfos[1]['imageLink']} textColor={toolInfos[1]['textColor']} shadowColor={toolInfos[1]['shadowColor']}/>
                    {/* <NumberShowcase totalNum={sectionTwoInfos[numbers+0]['total']} text={sectionTwoInfos[numbers+0]['text']}/> */}
                    {/* <NumberShowcase totalNum={sectionTwoInfos[numbers+1]['total']} text={sectionTwoInfos[numbers+1]['text']}/> */}
                    {/* <NumberShowcase totalNum={sectionTwoInfos[numbers+2]['total']} text={sectionTwoInfos[numbers+2]['text']}/> */}

                    </div>
                    <div className='home-page-section-two-left-btn-container'>
                        <div className='home-page-section-two-about-btn-container'>
                            <AboutButtonPrimary type={3} text={'Book Demo'} btnAction={handleBookDiscoveryCall}/>
                        </div>
                        <div className='home-page-section-two-btn-img-bg-wrapper'>
                            <img src={demoImageOne} alt="book demo"/>
                        </div>
                    </div>
                </div>
                 <div className='home-page-section-two-inner-right-container'>
                    <div className='home-page-section-two-inner-right-element-wrapper'>
                        <div className='home-page-section-two-inner-right-bg-filter'></div>
                        <div className='home-page-section-two-inner-right-image-container'>
                            <img src={dataDrivenImageThree} alt="section-two"/>
                            {/* <div className='home-page-section-two-inner-right-shape-container'>
                            <img id="home-section-two-sp-1" src={shapeOne} alt="shape"/>
                            <img id="home-section-two-sp-2" src={shapeTwo} alt="shape"/>
                            <img id="home-section-two-sp-4" src={shapeFour} alt="shape"/>
                            <img id="home-section-two-sp-5" src={shapeFive} alt="shape"/>
                            <img id="home-section-two-sp-6" src={shapeSix} alt="shape"/>
                            <img id="home-section-two-sp-7" src={shapeSeven} alt="shape"/>
                            <img id="home-section-two-sp-8" src={shapeEight} alt="shape"/>
                            <img id="home-section-two-sp-9" src={shapeNine} alt="shape"/>
                        </div> */}
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* <div className='home-page-section-two-bg-image-wrapper-one'></div> */}
            {/* <div className='home-page-section-two-bottom-info-holder'>
                <p>We work with forward thinking people centric organizations</p>
                <div className='home-page-section-two-sponsor-container'>
                    <HomePageSectionNineOurClients handleMenuActionAlternate={handleMenuActionAlternate}/>
                </div>
            </div> */}
        </section>
    );
};

export default HomePageSectionTwo;