import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import MainViewLanding from './components/MainView/MainViewLanding/MainViewLanding';
import AboutPage from './pages/AboutPage/AboutPage';
import ContactPage from './pages/ContactPage/ContactPage';
import GetInTouchPage from './pages/GetInTouchPage/GetInTouchPage';
import HomePage from './pages/HomePage/HomePage';
import ResourcesPage from './pages/ResourcesPage/ResourcesPage';
import ServicesPage from './pages/ServicesPage/ServicesPage';
import SingleResourcePage from './pages/SingleResourcePage/SingleResourcePage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<MainViewLanding/>}/> */}
        <Route path="/" element={<HomePage/>}/>
        <Route path="/home" element={<HomePage/>}/>
        {/* <Route path="/about" element={<AboutPage/>}/> */}
        <Route path="/contact" element={<GetInTouchPage/>}/>
        <Route path="/resources" element={<ResourcesPage status={0}/>}/>
        <Route path="/services" element={<ServicesPage/>}/>
        <Route path="/resources/:resId" element={<SingleResourcePage/>}/>
        <Route path="/*" element={<HomePage/>}/>
        {/* <Route path="/*" element={<MainViewLanding/>}/> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
