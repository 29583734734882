import React, { useEffect, useState } from 'react';
import './SingleResourcePage.css'
import { useParams } from 'react-router-dom';
import SectionTitleOne from '../../components/Shared/SectionTitleOne/SectionTitleOne';
import allResources from '../../hooks/allResources';
import SingleResourceContainer from '../../components/singleResourcePageComponents/SingleResourceContainer/SingleResourceContainer';
import PopUpOne from '../../components/popUps/PopUpOne/PopUpOne';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import HomePageSectionFive from '../../components/HomePageSectionFive/HomePageSectionFive';
import { GetRandomNumberMultilple } from '../../hooks/GetRandomNumber';
import ResourcesCardContainer from '../../components/resourcesComponents/ResourcesCardContainer/ResourcesCardContainer';
import AllResource from '../../hooks/allResources';
import Footer from '../../components/Shared/Footer/Footer';

const timeouts = []
const SingleResourcePage = () => {
    const {resId} = useParams() 
    const {cardsInfo} = allResources()
    const [resIndexes,setResIndexes] = useState([0,1,2])

    const [isOpen,setIsOpen] = useState(false);
    const [isLarge,setIsLarge] = useState(false);
    const [singleResIndex,setSingleResIndex] = useState(2)


    const[isPopUpOpen,setIsPopUpOpen] = useState(false);
    const[popUpType,setPopUpType] = useState(0);

    useEffect(()=>{
        const ansArr = GetRandomNumberMultilple(3,6)
        console.log(ansArr)
    },[])



    const handlePopUp = (type) =>{
        setPopUpType(type)
        setIsPopUpOpen(true)
        if(type !== 4){
            const myTimeOut = setTimeout(()=>{
                console.log('timedOut')
                setIsPopUpOpen(false)
                setPopUpType(0)
            },4000)
            timeouts.push(myTimeOut)
            return () => clearTimeout(myTimeOut)
        }
    }

    const handleClosePopUpBtn = () =>{
        setIsPopUpOpen(false)
        setPopUpType(0)
        for(let i=0;i<timeouts.length;i++){
            clearTimeout(timeouts[i])
        }
    }
    
    const handleMenuActionAlternate = ()=>{
        const myDiv = document.getElementById('navigation-controller-main-wrapper-id')
        if(isOpen){
            myDiv.style.right='-100vw';
            setIsOpen(false);
        }
    }
    
    useEffect(()=>{
        const myDiv = document.getElementById('single-resource-page-main-container')
        const myDivSize = myDiv.getBoundingClientRect();
        const tempN = myDivSize.width
        if(tempN>801){
            setIsOpen(false);
            handleMenuActionAlternate()
            setIsLarge(true)
        }
        else{
            setIsLarge(false)
        }
    },[])

    window.addEventListener('resize',e=>{
        const myDiv = document.getElementById('single-resource-page-main-container')
        const myDivSize = myDiv.getBoundingClientRect();
        if(!myDivSize){
            return
        }
        const tempN = myDivSize.width
        if( isOpen && tempN>801){
            setIsOpen(false);
            handleMenuActionAlternate()
        }
        if(tempN<=801){
            setIsLarge(false)
        }
        else{
            setIsLarge(true)
        }
    })
    return (
        <div className='single-resource-page-main-container' id="single-resource-page-main-container">
            <div style={{position:'absolute'}}>
                <NavigationBar handlePopUp={handlePopUp} isOpen={isOpen} setIsOpen={setIsOpen}/>
            </div>
            <div className='shared-main-window-page-bottom-container' onClick={()=>handleMenuActionAlternate()}>
            {isPopUpOpen && popUpType!== 0 && <PopUpOne popUpType={popUpType} handleClosePopUpBtn={handleClosePopUpBtn} handlePopUp={handlePopUp} />}
                    <SingleResourceContainer cardInfo={cardsInfo[resId]}/>
                    <HomePageSectionFive  handleMenuActionAlternate={handleMenuActionAlternate}/>
                    <div className='resources-page-all-resources-outer-container'>
                    <div>
                        <div className='single-resource-page-recommended-reading-title-container'>
                            <h2>Recommended Readings</h2>
                        </div>
                        <ResourcesCardContainer total={3} cardsInfo={cardsInfo}/>
                    </div>
                </div>
                <Footer handlePopUp={handlePopUp} />
            </div>
        </div>
    );
};

export default SingleResourcePage;