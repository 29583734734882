
import resourceImageDefaultOne from '../assets/images/pages-images/resources-page-images/resources-image-default.png'
import resourceImageOne from '../assets/images/pages-images/resources-page-images/resources-image-one.png'
import resourceImageTwo from '../assets/images/pages-images/resources-page-images/resources-image-two.png'
import resourceImageThree from '../assets/images/pages-images/resources-page-images/resources-image-three.png'

const AllResource=()=>{
        const cardsInfo = [
            {
                id:0,
                imageLink: resourceImageOne,
                resTitle:"Increase engagement and learning transfer with principles from game design zero.",
                resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
                resType:"ATD Article",
                resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
                resDuration:"2 mins"
            },
            {
                id:1,
                imageLink: resourceImageDefaultOne,
                resTitle:"Increase engagement and learning transfer with principles from game design one.",
                resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
                resType:"HBR Article",
                resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
                resDuration:"2 mins"
            },
            {
                id:2,
                imageLink: resourceImageTwo,
                resTitle:"Increase engagement and learning transfer with principles from game design two.",
                resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
                resType:"ATD Article",
                resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
                resDuration:"2 mins"
            },
            {
                id:3,
                imageLink: resourceImageThree,
                resTitle:"Increase engagement and learning transfer with principles from game design three.",
                resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
                resType:"HBR Article",
                resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
                resDuration:"2 mins"
            },
            {
                id:4,
                imageLink: resourceImageDefaultOne,
                resTitle:"Increase engagement and learning transfer with principles from game design four.",
                resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
                resType:"ATD Article",
                resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
                resDuration:"2 mins"
            },
            {
                id:5,
                imageLink: resourceImageTwo,
                resTitle:"Increase engagement and learning transfer with principles from game design five.",
                resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer.",
                resType:"ATD Article",
                resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
                resDuration:"2 mins"
            },
            {
                id:6,
                imageLink: resourceImageThree,
                resTitle:"Increase engagement and learning transfer with principles from game design six.",
                resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer. asdfdsf",
                resType:"ATD Article",
                resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
                resDuration:"2 mins"
            },
        ]
        return {cardsInfo};
}

export default AllResource;