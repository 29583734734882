import React, { useState } from 'react';
import SectionTitleOne from '../../Shared/SectionTitleOne/SectionTitleOne';
import './GetInTouchForm.css'


import getInTouchBgImageOne from '../../../assets/images/pages-images/get-in-touch-page-images/get-in-touch-form-bg-image.png';

import getTouchImageOne from '../../../assets/images/pages-images/get-in-touch-page-images/get-in-touch-img-one.svg'
import HomeTitleSecondary from '../../Shared/HomeTitleSecondary/HomeTitleSecondary';
import AboutButtonPrimary from '../../buttons/AboutButtonPrimary/AboutButtonPrimary';
import { addDoc, collection, getFirestore, Timestamp } from 'firebase/firestore';

const GetInTouchForm = ({handlePopUp}) => {
    const [userName,setUserName] = useState("")
    const [userEmail,setUserEmail] = useState("")
    const [userConcern,setUserConcern] = useState("")

    const storeDb = getFirestore()

    const handleConcernSubmit = () =>{
        if((!userEmail && !userName) || (!userName && !userConcern) || (!userEmail && !userConcern) || (!userEmail && !userName && !userConcern)){
            // alert("please give a valid email.")
            handlePopUp(2)
            setUserName("");
            setUserEmail("");
            setUserConcern("");
            return
        }
        if(!userEmail || (userEmail && !userEmail.includes('@'))){
            setUserName("");
            setUserEmail("");
            setUserConcern("");
            handlePopUp(3)
            return
        }
        if(!userName){
            setUserName("");
            setUserEmail("");
            setUserConcern("");
            handlePopUp(7)
            return
        }
        if(!userConcern){
            setUserName("");
            setUserEmail("");
            setUserConcern("");
            handlePopUp(8)
            return
        }
        else{
            return
            
            // const cityRef = doc(storeDb, 'sile', `subscribers`);
            const subRef = collection(storeDb, 'concerns/');
            addDoc(subRef,{
                userEmail:userEmail,
                userName:userName,
                userConcern:userConcern,
                time: Timestamp.fromDate(new Date())
            })
            .then(()=>{
                console.log('data added')
                handlePopUp(1) // 1->successful pop up type
            })
            .then(()=>{
                handlePopUp(1)
            })
            .catch(err=>{ 
                console(err.message)
                handlePopUp(3) // -1 -> unsuccessful pop up type
            })
            
            
            // const myData = {
            //     email: subEmail
            // }
            // await addDoc(doc(storeDb,subRef), myData);
            setUserName("");
            setUserEmail("");
            setUserConcern("");
        }
    }
    return (
        <div className='get-in-touch-form-main-container'>
            {/* <div className='get-in-touch-form-main-section-title-container'>
                    <SectionTitleOne rotY={10} rotZ={14} wt={148} ht={66} leftD={285} text={"Lets's get in touch"}/>
                    <HomeTitleSecondary text={"Let’s Get in Touch"}/>
            </div> */}
            <div  className='get-in-touch-form-get-in-touch-text-bg'>
                    <p>Let’s Get in Touch</p>
                </div>
            {/* <div className='get-in-touch-form-summary-container'>
                <p>Creating next generation of experiential training.</p>
            </div> */}
            <div className='get-in-touch-form-outer-holder'>
                <div className='get-in-touch-form-outer-left-container'>
                    <div className='get-in-touch-form-outer-left-image-container'>
                        {/* <img src={getTouchImageOne} alt="get in touch" /> */}
                        <div className='get-in-touch-form-inner-left-text-container'>
                            <p>CREATING THE <span className='get-in-touch-form-inner-left-text-container-sp1'>NEXT GENERATION</span><br/> OF <span className='get-in-touch-form-inner-left-text-container-sp2'>  EXPERIENTIAL TRAINING</span></p>
                        </div>
                    </div>

                </div>
                <div className='get-in-touch-form-inner-container'>
                    {/* <div className='get-in-touch-form-name-email-container'> */}
                        <div className='get-in-touch-form-input-info-container'>
                            <p>Name</p>
                            <input onChange={e=>setUserName(e.target.value)} value={userName} type="text" />
                        </div>
                        <div className='get-in-touch-form-input-info-container'>
                            <p>E-mail</p>
                            <input onChange={e=>setUserEmail(e.target.value)} value={userEmail}  type="email" />
                        </div>
                    {/* </div> */}
                    <div className='get-in-touch-form-user-reports-container'>
                        <p>Any concerns in your mind?</p>
                        <textarea  onChange={e=>setUserConcern(e.target.value)} value={userConcern} ></textarea>
                    </div>
                    <div className='get-in-touch-form-inner-submit-btn-container'>
                            <AboutButtonPrimary type={5} text={'Submit'} btnAction={()=>{handleConcernSubmit()}}/>
                    </div>
                    <div className='get-in-touch-form-inner-container-form-bottom-random-colors-holder'></div>
                </div>
            </div>
        </div>
    );
};

export default GetInTouchForm;