import React from 'react';
import './ButtonOne.css';

import menuIconImage from '../../../assets/images/icons/menu-icon-one.svg';

const ButtonOne = ({isOpen,text,type,btnAction}) => {
    if(type===2){
        return(
        <button className='button-button-one-main-container-type-two' onClick={()=>btnAction()}>
            {/* <img src={menuIconImage} alt="menu-icon"/> */}
            {`${!isOpen?'≡':'×'}`}
        </button>
        )
    }
    return (
        <button className='button-button-one-main-container' onClick={()=>btnAction()}>
            {text}
        </button>
    );
};

export default ButtonOne;