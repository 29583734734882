import React from 'react';
import './ResourcesLandingCard.css';
import imageOne from '../../../assets/images/pages-images/resources-page-images/resources-image-one.png';
import { NavHashLink } from 'react-router-hash-link';


        // id:6,
        // imageLink: resourceImageThree,
        // resTitle:"Increase engagement and learning transfer with principles from game design six.",
        // resDes:"Gamification has been a trendy word in the industry for several years now, but what does it really mean? Discover the basic attributes of games and what kinds of games are best for different types of learning. Find out how the basic elements of games can be used to increase learning transfer. asdfdsf",
        // resType:"ATD Article",
        // resPublished:"Thu, 22 Dec 2022 16:15:13 GMT",
        // resDuration:"2 mins"

const ResourcesLandingCard = ({cardInfo}) => {
    const {id,imageLink,resTitle,resDes,resType,resDuration,resPublished} = cardInfo
    const resourcesTitle = "Why effectively training for human skills is more important than ever"
    const resourcesDes = "Human skills are simply skills that are exclusive to human beings, and no AI or automation can replace these skills.The need for individuals, resilient and adaptive to rapidly changing environments, is the order of the day. LinkedIn’s workplace learning report 2020 states that the #1 priority of talent development teams is training employees for creativity, persuasion, collaboration, adaptability, and emotional intelligence."
    const resourcesType = "ATD Articles"
    const resourcesDuration = "2 mins"
    return (
        <NavHashLink className="resources-landing-outer-nav-link-container" to={`/resources/${id}`}>
        <div className='resources-landing-main-container'>
            <div className='resources-landing-left-image-holder'>
                <img src={imageLink} alt="resources"/>
                {/* <img src={imageOne} alt="resources"/> */}
            </div>
            <div className='resources-landing-right-info-container'>
                <div className='resources-landing-right-info-title-container'>
                    <h2>{resTitle}</h2>
                </div>
                <div className='resources-landing-right-info-description-container'>
                    <p>{resDes}</p>
                </div>
                <div className='resources-landing-right-info-bottom-info-container'>
                    <div className='resources-landing-right-info-bottom-info-left-container'>
                        <p>{resType}</p>
                    </div>
                    <div className='resources-landing-right-info-bottom-info-right-container'> 
                        <p>{resDuration} read</p>
                    </div>
                </div>
            </div>
        </div>
        </NavHashLink>
    );
};

export default ResourcesLandingCard;