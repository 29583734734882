import React, { useState } from 'react';
import './HomePageSectionFive.css'
import subscribeImageOne from '../../assets/images/pages-images/home-page-images/newsletter-bgimage-one.png'

import MainButtonsOne from '../buttons/MainButtonsOne/MainButtonsOne';

import imageFive from '../../assets/images/backgrounds/home-imge-five.png'

import imageArrowOne from '../../assets/images/icons/union-icon-white-one.png'
import { addDoc, collection, getFirestore, Timestamp } from 'firebase/firestore';

const HomePageSectionFive = ({handlePopUp,handleMenuActionAlternate}) => {
    const storeDb = getFirestore()
    const [subEmail,setSubEmail] = useState('');



    const handleSubscriber = async () =>{
        if(!subEmail || (!subEmail.includes('@'))){
            // alert("please give a valid email.")
            handlePopUp(3)
            setSubEmail("");
            return
        }
        else{
            
            // const cityRef = doc(storeDb, 'sile', `subscribers`);
            const subRef = collection(storeDb, 'sileCommunity');
            addDoc(subRef,{
                email:subEmail,
                time: Timestamp.fromDate(new Date())
            })
            .then(()=>{
                console.log('data added')
                handlePopUp(5) // 1->successful pop up type
            })
            .catch(err=>{ 
                console(err.message)
                handlePopUp(3) // -1 -> unsuccessful pop up type
            })
            
            
            // const myData = {
            //     email: subEmail
            // }
            // await addDoc(doc(storeDb,subRef), myData);
            setSubEmail("");
        }
    }



    return (
        <section  onClick={handleMenuActionAlternate} className='home-page-section-five-main-container'>
            <div className='home-page-section-five-inner-container'>
                <div className='home-page-section-five-left-main-wrapper'>
                    <div className='home-page-section-five-left-title-container'>
                        <div className='home-page-section-five-left-title-bg-image-holder'>
                            <img src={subscribeImageOne} alt="subscribe"/>
                        </div>
                        <div>
                            <h2>Subscribe to our news letter</h2>
                        </div>
                    </div>
                    <ul className='home-page-section-five-left-training-details'>
                        <li>Facilitation toolkit with ice breakers, videos and much more</li>
                        <li>Free online games and tools to engage your audience</li>
                        <li>Games that can help you capture key employee insights</li>
                        <li>Actionable's on delivering jaw dropping programs</li>
                    </ul>
                    {/* <div className='home-page-section-five-left-btn-main-container'>
                        <MainButtonsOne text={"Book a discovery call"} btnAction={()=>console.log("Fifth section book")}/>
                    </div> */}
                </div>
                <div className='home-page-section-five-right-main-wrapper'>
                    <div className='home-page-section-five-right-main-image-container'>
                        <div className='home-page-section-five-join-community-form-container'>
                            <h2 className='home-page-section-five-join-community-form-title'>To join the SILE Facilitator's community!</h2>
                            <div className='home-page-section-five-email-input-submit-container'>
                                <p>E-mail ID</p>
                                <div className='home-page-section-five-input-btn-container'>
                                    <input type="email" onChange={e=>setSubEmail(e.target.value)} value={subEmail} />
                                    <div onClick={()=>{handleSubscriber()}} className='home-page-section-five-email-submit-btn-container'>
                                        <img src={imageArrowOne} alt="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomePageSectionFive;