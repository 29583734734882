import React, { useState } from 'react';
import './MainViewDisplay.css'
import { addDoc, collection, doc, getFirestore, setDoc, Timestamp, updateDoc } from "firebase/firestore"; 

// imported image
import randomImageOne from '../../../assets/images/backgrounds/top-color-mixture-1.png'
import MainButtonsOne from '../../buttons/MainButtonsOne/MainButtonsOne';
import PopUpOne from '../../popUps/PopUpOne/PopUpOne';

const launchingText = "For early access to our training games at deep discounted prices"

const timeouts = []
const MainViewDisplay = ({page,type,handleMenuActionAlternate,handlePopUp}) => {
    const[rotX,setRotX] = useState(0)
    const[rotY,setRotY] = useState(0)
    const[sector,setSector] = useState(1)
    const[isPopUpOpen,setIsPopUpOpen] = useState(false);
    const[popUpType,setPopUpType] = useState(0);
    const storeDb = getFirestore()

    const [subEmail,setSubEmail] = useState('');


    // const handlePopUp = (type) =>{
    //     setPopUpType(type)
    //     setIsPopUpOpen(true)
    //     const myTimeOut = setTimeout(()=>{
    //         console.log('timedOut')
    //         setIsPopUpOpen(false)
    //         setPopUpType(0)
    //     },4000)
    //     timeouts.push(myTimeOut)
    //     return () => clearTimeout(myTimeOut)
    // }

    const handleClosePopUpBtn = () =>{
        setIsPopUpOpen(false)
        setPopUpType(0)
        for(let i=0;i<timeouts.length;i++){
            clearTimeout(timeouts[i])
        }
    }

    const handleSubscriber = async () =>{
        if(!subEmail || (!subEmail.includes('@'))){
            // alert("please give a valid email.")
            handlePopUp(3)
            setSubEmail("");
            return
        }
        else{
            
            // const cityRef = doc(storeDb, 'sile', `subscribers`);
            const subRef = collection(storeDb, 'subscribers');
            addDoc(subRef,{
                email:subEmail,
                time: Timestamp.fromDate(new Date())
            })
            .then(()=>{
                console.log('data added')
                handlePopUp(6) // 1->successful pop up type
            })
            .catch(err=>{ 
                console(err.message)
                handlePopUp(3) // -1 -> unsuccessful pop up type
            })
            
            
            // const myData = {
            //     email: subEmail
            // }
            // await addDoc(doc(storeDb,subRef), myData);
            setSubEmail("");
        }
    }
    
    window.addEventListener('mousemove', (e) => {
        // if(type === 2){
        //     return
        // }
        const myDiv = document.getElementById('main-view-display-main-container')
        const myDivSize = myDiv.getBoundingClientRect()
        
        const targetDiv = document.getElementById('main-view-display-launching-card-container')
        const targetDivSize = targetDiv.getBoundingClientRect()
        const targetDivXStart = targetDivSize.y
        // const targetDivXEnd = targetDivSize.y+targetDivSize.height
        const targetDivXEnd = targetDivSize.y+384

        const targetDivOne = document.getElementById('main-view-display-inner-container')
        
        const myPosX = e.clientX
        const myDivW = myDivSize.width
        const cW = myDivW/2
        
        const myPosY = e.clientY
        const myDivH = myDivSize.height
        const cH = myDivH/2
        
        if(myPosX > cW -300 && myPosX < cW +300 ){
            // setRotY(0)
            // targetDiv.style.background= "blue";
            targetDivOne.style.transform= "perspective(1000px) rotateY(0deg) rotateX(0deg)";
        }
        else if(myPosX < cW){
            // targetDiv.style.transform= "perspective(1000px) rotateY(-10deg)";
            if(myPosY >cH){
                targetDivOne.style.transform= "perspective(1000px) rotateY(-10deg) rotateX(-10deg)";
            }
            else if(myPosY == cH){
                targetDivOne.style.transform= "perspective(1000px) rotateY(-10deg) rotateX(0deg)";
            }
            else{
                targetDivOne.style.transform= "perspective(1000px) rotateY(-10deg) rotateX(10deg)";
            }
            // setRotY(-10)
            // const absDis = Math.abs((cW)-myPosX)
            // const rotXTemp = parseInt(absDis/30)
            // console.log(rotXTemp)
            // if(rotXTemp <=10){
            //     setRotY(0);
            // }
            // else{
            //     if(rotXTemp>20){
            //         setRotY(-10)
            //     }
            //     else{
            //         setRotY(10-absDis);
            //     }
            // }
            // targetDiv.style.background= "black";
        }
        else if(myPosX === cW){
            // targetDiv.style.transform= "perspective(1000px) rotateY(0deg)";
            targetDivOne.style.transform= "perspective(1000px) rotateY(0deg) rotateX(0deg)";
            // targetDiv.style.background= "yellow";
            // setRotY(0)
            // const absDis = Math.abs((cW)-myPosX)
            // const rotXTemp = parseInt(absDis/30)
            // console.log(rotXTemp)
            // if(rotXTemp <=10){
            //     setRotY(0);
            // }
            // else{
            //     if(rotXTemp>20){
            //         setRotY(10)
            //     }
            //     else{
            //         setRotY(absDis-10);
            //     }
            // }
        }
        else{
            // targetDiv.style.transform= "perspective(1000px) rotateY(10deg)";
            // targetDivOne.style.transform= "perspective(1000px) rotateY(10deg)";
            if(myPosY >cH){
                targetDivOne.style.transform= "perspective(1000px) rotateY(10deg) rotateX(-10deg)";
            }
            else if(myPosY == cH){
                targetDivOne.style.transform= "perspective(1000px) rotateY(10deg) rotateX(0deg)";
            }
            else{
                targetDivOne.style.transform= "perspective(1000px) rotateY(10deg) rotateX(10deg)";
            }
            // targetDiv.style.background= "orange";
            // setRotY(10)
            // const absDis = Math.abs((cW)-myPosX)
            // const rotXTemp = parseInt(absDis/30)
            // console.log(rotXTemp)
            // if(rotXTemp <=10){
            //     setRotY(0);
            // }
            // else{
            //     if(rotXTemp>20){
            //         setRotY(10)
            //     }
            //     else{
            //         setRotY(absDis-10);
            //     }
            // }
        }
    });
    // return (
    //     <div onClick={()=>{handleMenuActionAlternate()}} id="main-view-display-main-container" className='main-view-display-main-container'>
    //         {isPopUpOpen && popUpType!== 0 && <PopUpOne popUpType={popUpType} handleClosePopUpBtn={handleClosePopUpBtn}/>}
    //         <section style={{'--rotX':`${rotX}deg`,'--rotY':`${rotY}deg`}} className='main-view-display-inner-container' id="main-view-display-inner-container">
    //             <div className='main-view-display-launching-card-container' id='main-view-display-launching-card-container'>
    //                 <div className='main-view-display-launching-card-title-wrapper'>
    //                     {/* <h2>Launching On</h2> */}
    //                     <h2>Tools that help you facilitate</h2>
    //                 </div>
    //                 {/* <div className='main-view-display-launching-card-time-showcase'>
    //                     <div className='main-view-display-launching-card-time-showcase-left'>
    //                         <h2>11th December</h2>
    //                         <p>Sunday</p>
    //                     </div>
    //                     <div className='main-view-display-launching-card-time-showcase-right'>
    //                         <h2>11 AM</h2>
    //                     </div>
    //                 </div> */}
    //                 {/* <div className='main-view-display-launching-card-random-image-holder'>
    //                     <img src={randomImageOne} alt="random-colors"/>
    //                 </div> */}
    //                 <div  className='main-view-display-launching-card-bottom-text-container'>
    //                     <p>{launchingText}</p>
    //                 </div>
    //                 <div className='main-view-display-launching-card-user-information-container'>
    //                     <input value={subEmail} onChange={e=>setSubEmail(e.target.value)} type="email" placeholder='Enter your mail address'/>
    //                     <div className='main-view-display-launching-card-user-information-container-button-wrapper'>
    //                         <button onClick={handleSubscriber}>Subscribe Now!</button>
    //                     </div>
    //                     {/* <MainButtonsOne text={"Subscribe Now!"} btnAction={()=>console.log('subscribe now')}/> */}
    //                 </div>
    //             </div>
    //         </section>
    //     </div>
    // );
    
    return (
    <div onClick={()=>{handleMenuActionAlternate()}} id="main-view-display-main-container" className='main-view-display-main-container'>
        {isPopUpOpen && popUpType!== 0 && <PopUpOne popUpType={popUpType} handleClosePopUpBtn={handleClosePopUpBtn}/>}
        <section style={{'--rotX':`${rotX}deg`,'--rotY':`${rotY}deg`}} className='main-view-display-inner-container' id="main-view-display-inner-container">
            <div className='main-view-display-launching-card-container' id='main-view-display-launching-card-container'>
                <div className='main-view-display-launching-card-title-wrapper'>
                    {/* <h2>Launching this Christmas</h2> */}
                    <h2>Tools that help you facilitate</h2>
                </div>
                <div className='main-view-display-launching-card-time-showcase'>
                    {/* <div className='main-view-display-launching-card-time-showcase-left'>
                        <h2>25th December</h2>
                        <p>Sunday</p>
                    </div> */}
                    <div className='main-view-display-launching-card-time-showcase-right'>
                        <h2>The "WoW" Experience</h2>
                        {/* <h2>00:01 AM</h2> */}
                    </div>
                </div>
                <div className='main-view-display-launching-card-random-image-holder'>
                    <img src={randomImageOne} alt="random-colors"/>
                </div>
                <div className='main-view-display-launching-card-bottom-text-container'>
                    {/* <p>{launchingText}</p> */}
                    <p>We take care of the learning environment,<br/> so you   an focus on <span style={{color:"#F07CEC"}}>delivering learning</span></p>
                </div>
                <div className='main-view-display-launching-card-user-information-container'>
                    <input value={subEmail} onChange={e=>setSubEmail(e.target.value)} type="email" placeholder='Enter your mail address'/>
                    <div className='main-view-display-launching-card-user-information-container-button-wrapper'>
                        {page !== 1 && <button onClick={handleSubscriber}>Subscribe Now!</button>}
                        {page === 1 && <button onClick={handleSubscriber}>Let's talk</button>}
                    </div>
                    {/* <MainButtonsOne text={"Subscribe Now!"} btnAction={()=>console.log('subscribe now')}/> */}
                </div>
            </div>
        </section>
    </div>
);
};

export default MainViewDisplay;
// return (
//     <div onClick={()=>{handleMenuActionAlternate()}} id="main-view-display-main-container" className='main-view-display-main-container'>
//         {isPopUpOpen && popUpType!== 0 && <PopUpOne popUpType={popUpType} handleClosePopUpBtn={handleClosePopUpBtn}/>}
//         <section style={{'--rotX':`${rotX}deg`,'--rotY':`${rotY}deg`}} className='main-view-display-inner-container' id="main-view-display-inner-container">
//             <div className='main-view-display-launching-card-container' id='main-view-display-launching-card-container'>
//                 <div className='main-view-display-launching-card-title-wrapper'>
//                     {/* <h2>Launching On</h2> */}
//                     <h2>Tools that help you facilitate</h2>
//                 </div>
//                 <div className='main-view-display-launching-card-time-showcase'>
//                     {/* <div className='main-view-display-launching-card-time-showcase-left'>
//                         <h2>8th December</h2>
//                         <p>Thursday</p>
//                     </div> */}
//                     <div className='main-view-display-launching-card-time-showcase-right'>
//                         <h2>The "WoW" Experience</h2>
//                         {/* <h2>6:30 PM</h2> */}
//                     </div>
//                 </div>
//                 <div className='main-view-display-launching-card-random-image-holder'>
//                     {/* <img src={randomImageOne} alt="random-colors"/> */}
//                 </div>
//                 <div className='main-view-display-launching-card-bottom-text-container'>
//                     {/* <p>{launchingText}</p> */}
//                     <p>We take care of the learning environment,<br/> so you   an focus on <span style={{color:"#F07CEC"}}>delivering learning</span></p>
//                 </div>
//                 <div className='main-view-display-launching-card-user-information-container'>
//                     <input value={subEmail} onChange={e=>setSubEmail(e.target.value)} type="email" placeholder='Enter your mail address'/>
//                     <div className='main-view-display-launching-card-user-information-container-button-wrapper'>
//                         {page !== 1 && <button onClick={handleSubscriber}>Subscribe Now!</button>}
//                         {page === 1 && <button onClick={handleSubscriber}>Let's talk</button>}
//                     </div>
//                     {/* <MainButtonsOne text={"Subscribe Now!"} btnAction={()=>console.log('subscribe now')}/> */}
//                 </div>
//             </div>
//         </section>
//     </div>
// );